import React, { useState } from 'react'
import Loading from 'views/Loading'

const btnStyle = {
    margin: 20,
    backgroundColor: '#5A3571',
    padding: '20px 50px',
    color: 'white',
    border: 'none',
    fontSize: 15,
    fontWeight: 600,
    borderRadius: 5,
    cursor: 'pointer',
}

export default () => {
    const [selectedChat, setSelectedChat] = useState(null)
    const [iframeLoaded, setIframeLoaded] = useState(false)

    const getUrl = chat => {
        const day = new Date().getDay()
        const monToFri = day >= 1 && day <= 5,
            saturday = day === 6,
            sunday = day === 7

        switch (chat) {
            case 'activations':
                if (monToFri)
                    return 'https://crm.inxel.mobi/online/activaciones-distribuidores'
                if (saturday)
                    return 'https://crm.inxel.mobi/online/activaciones-distribuidores-fin-semana'
                return null
            case 'config':
                if (monToFri)
                    return 'https://crm.inxel.mobi/online/configuraciones-distribuidores'
                if (saturday)
                    return 'https://crm.inxel.mobi/online/configuraciones-distribuidores-sabado'
                if (sunday)
                    return 'https://crm.inxel.mobi/online/configuraciones-distribuidores-domingo'
            case 'quality':
                return 'https://crm.inxel.mobi/online/calidad-distribuidores'
        }
    }

    const selectHandler = e => {
        setSelectedChat(e.target.value)
    }

    return (
        <div>
            <h1 style={{ textAlign: 'center' }}>Asistencia total InXel</h1>

            <div
                style={{
                    minHeight: '60vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {selectedChat ? (
                    getUrl(selectedChat) ? (
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <button
                                    onClick={() => {
                                        setSelectedChat(null)
                                        setIframeLoaded(false)
                                    }}
                                    style={btnStyle}
                                >
                                    Seleccionar otro chat
                                </button>
                            </div>
                            {!iframeLoaded ? (
                                <Loading
                                    style={{ position: 'absolute', top: 0, left: 0 }}
                                />
                            ) : null}
                            <iframe
                                onLoad={() => setIframeLoaded(true)}
                                id="chat-frame"
                                style={{
                                    width: '100vh',
                                    height: '80vh',
                                    flex: 1,
                                }}
                                src={getUrl(selectedChat)}
                                frameborder="0"
                            ></iframe>
                        </div>
                    ) : (
                        <div>
                            {alert(
                                'No contamos con asistencia de la ' +
                                    'configuracion seleccionada en ' +
                                    'este día de la semana.',
                            )}
                            <Dropdown {...{ selectHandler }} />
                        </div>
                    )
                ) : (
                    <Dropdown {...{ selectHandler }} />
                )}
            </div>
        </div>
    )
}

const Dropdown = ({ selectHandler }) => (
    <select onChange={e => selectHandler(e)} style={btnStyle}>
        <option hidden value="">
            Seleccione un chat
        </option>
        <option value="activations">Activaciones</option>
        <option value="config">Configuraciones</option>
        <option value="quality">Calidad</option>
    </select>
)
