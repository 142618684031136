import axios from "axios";

const getPlanes = new Promise((resolve) => {

    axios.get("https://api.inxel.mx/catalog/planes/ventas").then(res => {
        resolve({
            status: 'OK',
            response: res.data
        });
    }).catch(error => {
        resolve({
            status: "ERROR",
            error
        })
    })
});
export default getPlanes;