import Joi from '@hapi/joi';

const ventaValidation = Joi.object({
    exists: Joi.boolean(),
    replace: Joi.boolean(),
    dn: Joi.string().min(10).max(10).empty(null).empty("").messages({
        "string.base": `El telefono de reemplazo debe ser solo texto`,
        "string.empty": `El telefono de reemplazo no debe ser vacio`,
        "string.min": `El telefono de reemplazo debe tener un minimo de {#limit} caracteres`,
        "string.max": `"El telefono de reemplazo debe tener un maximo de {#limit} caracteres `,
        "any.required": `El telefono de reemplazo  requerido`
    }),
    nombre: Joi.string().min(3).max(255).when('exists', {
        is: Joi.valid(false), then: Joi.required()
        //   , otherwise: Joi.valid('z')
    }).empty(null).empty('').messages({
        "string.base": `El nombre debe ser solo texto`,
        "string.empty": `El nombre no debe se vacio`,
        "string.min": `El nombre debe tener un minimo de {#limit} caracteres`,
        "string.max": `"El nombre debe tener un maximo de {#limit} caracteres `,
        "any.required": `Nombre requerido`
    }),
    amaterno: Joi.string().min(3).max(255).required().messages({
        "string.base": `Los apellidos deben ser solo texto`,
        "string.empty": `Los apellidos no deben estar vacios`,
        "string.min": `Los apellidos deben tener un minimo de {#limit} caracteres`,
        "string.max": `"Los apellidos deben tener un maximo de {#limit} caracteres `,
        "any.required": `Apellidos requeridos`
    }),
    aparteno: Joi.string().min(3).max(255).required().messages({
        "string.base": `Los apellidos deben ser solo texto`,
        "string.empty": `Los apellidos no deben estar vacios`,
        "string.min": `Los apellidos debe tener un minimo de {#limit} caracteres`,
        "string.max": `"Los apellidos debe tener un maximo de {#limit} caracteres `,
        "any.required": `Apellidos requeridos`
    }),
    tcontacto: Joi.string().min(10).max(10).required().messages({
        "string.base": `El telefono de contacto debe ser solo texto`,
        "string.empty": `El telefono de contacto no debe ser vacio`,
        "string.min": `El telefono de contacto debe tener un minimo de {#limit} caracteres`,
        "string.max": `"El telefono de contacto debe tener un maximo de {#limit} caracteres `,
        "any.required": `Telefono de contacto requerido`
    }),
    email: Joi.string().required().email({minDomainSegments: 2, tlds: {allow: ['com', 'net', 'mx']}}).messages({
        "string.base": `El Correo debe ser solo texto`,
        "string.empty": `El Correo no debe se vacio`,
        "string.min": `El Correo debe tener un minimo de {#limit} caracteres`,
        "string.max": `"El Correo debe tener un maximo de {#limit} caracteres `,
        "string.email": `Correo no válido`,
        "any.required": `Correo requerido`
    }),
    gender: Joi.string().required(),
    age: Joi.number().required(),
    origin_companie: Joi.string().min(1).max(255).messages({
        "string.base": `La compañia de origen debe ser solo texto`,
        "string.empty": `La compañia de origen no debe ser vacio`,
        "string.min": `La compañia de origen debe tener un minimo de {#limit} caracteres`,
        "string.max": `"La compañia de origen debe tener un maximo de {#limit} caracteres `,
        "any.required": `Compañia de origen requerido`
    }),
    product: Joi.string().min(1).max(255).required().messages({
        "string.base": `El producto debe ser solo texto`,
        "string.empty": `El producto no debe ser vacio`,
        "string.min": `El producto debe tener un minimo de {#limit} caracteres`,
        "string.max": `"El producto debe tener un maximo de {#limit} caracteres `,
        "any.required": `Producto requerido`
    }),
    tipoVenta: Joi.string().min(1).max(255).required().messages({
        "any.required": `Tipo de venta requerida`
    }),
    tporta: Joi.string().min(1).max(255).messages({
        "string.base": `El telefono a portar debe ser solo texto`,
        "string.empty": `El telefono a portar no debe ser vacio`,
        "string.min": `El telefono a portar debe tener un minimo de {#limit} caracteres`,
        "string.max": `"El telefono a portar debe tener un maximo de {#limit} caracteres `,
        "any.required": `telefono a portar  requerido`
    }),
    idType: Joi.any(),
    img1: Joi.any(),
    img2: Joi.any(),
    price: Joi.any(),
    months: Joi.any(),
    services:Joi.any(),

    nip: Joi.number().min(4).messages({
        "number.base": `El NIP es un número`,
        //"number.empty": `El NIP no debe estar`,
        "number.min": `El NIP debe tener un minimo de {#limit} números`,
        // "number.max": `El NIP debe tener un maximo de {#limit} números`,
        //"any.required": `telefono a portar  requerido`
    }),
    marca: Joi.string().min(1).max(255).messages({
        "string.base": `La marca debe ser solo texto`,
        "string.min": `Marca debe tener un minimo de {#limit} caracteres`,
        "string.max": `"Marca debe tener un maximo de {#limit} caracteres `,
        // "any.required": `telefono a portar  requerido`
    }),
    modelo: Joi.string().min(1).max(255).messages({
        "string.base": `El modelo debe ser solo texto`,
        "string.min": `Modelo debe tener un minimo de {#limit} caracteres`,
        "string.max": `"Modelo debe tener un maximo de {#limit} caracteres `,
    }),
    imei: Joi.number().min(5).required().messages({
        "any.required": `El IMEI es requerido`,
        "number.base": `El IMEI es un número`,
        "string.empty": `Inserta IMEI`,
        "number.min": `El IMEI tiene un minimo de {#limit} caracteres`,
    }),
    icc: Joi.string().min(20).max(20).required().messages({
        "string.base": `El ICC debe ser solo texto`,
        "string.empty": `Inserta ICC`,
        "string.min": `El ICC debe tener un minimo de {#limit} caracteres`,
        "string.max": `"El ICC debe tener un maximo de {#limit} caracteres `,
        "any.required": `ICC requerido`
    }),
    comprobante: Joi.string().min(20).messages({
        "string.base": `El Comprobante no tiene el formato correcto`,
        "string.empty": `Inserta Comprobante`,
        "string.min": `Comprobante no válido`,
     //   "string.max": `Comprobante no válido`,
       // "any.required": `Comprobante requerido`
    }),
    nir:Joi.any(),
    notification_id: Joi.any()
});


export default ventaValidation;

