import React, {useEffect, useState} from "react";
import {Button, Grid, makeStyles, Typography} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {useFileValidator} from "../../hooks/FileValidator";
import AlertSnackBar from "../AlertSnackBar";
//Imagenes de IDs
import IneFrontDefault from '../../assets/img/id/INEfrente.jpg';
import InePostDefault from '../../assets/img/id/INEvuelta.jpg';
import PermisoFrontDefault from '../../assets/img/id/licenciaF.jpg';
import PermisoPostDefault from '../../assets/img/id/licenciav.jpg';
import PassportDefault from '../../assets/img/id/pasaporte.jpg';
import CedulaFrontDefault from '../../assets/img/id/cédulaf.jpg';
import CedulaPostDefault from '../../assets/img/id/cédulaf.jpg';

const useStyles = makeStyles({
    idSize: {
        width: '100%',
        maxWidth: 280
    },
    noContainerCenter: {
        display: "flex", justifyContent: "center"
    }
});

export default function Identification({dataSup, setDataSup}) {
    const [idSelected, setIdSelected] = useState('0000');


    const handleChange = e => {
        setIdSelected(e.target.value);

    }
    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <Typography>
                Datos de identificación
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Grid container spacing={1} justify={'center'} alignItems={"center"}>
                <Grid item xs={12} md={4}>
                    <Typography align={"center"}>
                        Selecciona tipo de identificación
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormControl
                        fullWidth

                        //    className={classes.formControl}
                    >
                        <InputLabel id="demo-simple-select-label">Age</InputLabel>
                        <Select
                            //  labelId="select-label-identification"
                            id="select-identification"
                            value={idSelected}
                            name={'identification'}
                            onChange={handleChange}

                        >
                            <MenuItem value={`0000`}>INE</MenuItem>
                            <MenuItem value={`1111`}>Permiso de conducir</MenuItem>
                            <MenuItem value={`2222`}>Pasaporte</MenuItem>
                            <MenuItem value={`3333`}>Cédula profesional</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12}>
            <FilesCases data={dataSup} setData={setDataSup} oneCase={idSelected === '2222'} type={idSelected}/>
        </Grid>
    </Grid>
}

const FilesCases = ({data, setData, oneCase, type}) => {
    const classes = useStyles();
    const [ineFront, validIneFront, setIneFront, resetIneFront] = useFileValidator(['jpeg', "jpg", "png"]);
    const [openError, setOpenError] = useState(false);
    const [inePost, validInePost, setInePost, resetInePost] = useFileValidator(['jpeg', "jpg", "png"]);

    useEffect(() => {
        if (!validIneFront && ineFront !== null && ineFront === 'NONE') {
            setData({
                ...data,
                img1: null,
                idType: type
            })
            setOpenError(true);
        } else {
            setData({
                ...data,
                img1: ineFront,
                idType: type
            });
        }
    }, [validIneFront, ineFront]);

    useEffect(() => {
        if (!validInePost && inePost !== null && inePost === 'NONE') {
            setData({
                ...data,
                img2: null
            })
            setOpenError(true);
        } else {
            setData({
                ...data,
                img2: inePost
            });
        }
    }, [validInePost, inePost])

    const closeIneFrontError = () => {
        setOpenError(false);
    }

    const handleClickIneFront = (e) => {
        e.preventDefault();
        const doc = document.getElementById('file-inefront');
        doc.click();
    }
    const handleClickInePost = e => {
        e.preventDefault();
        const doc = document.getElementById('file-inepost');
        doc.click();
    }
    return <Grid container justify={'center'} spacing={1}>
        <Grid item xs={12} md={5}>
            <Grid container justify={'center'} spacing={1}>
                <Grid item xs={9} className={classes.noContainerCenter}>
                    <img className={classes.idSize}
                         src={validIneFront ? ineFront :
                             type === "0000" ? IneFrontDefault :
                                 type === "1111" ? PermisoFrontDefault :
                                     type === "2222" ? PassportDefault : CedulaFrontDefault
                         }
                         alt="Ine frontal"/>
                </Grid>
                <Grid item xs={9} className={classes.noContainerCenter}>
                    <Button
                        color={!validIneFront ? 'primary' : ''}
                        variant="contained"
                        onClick={handleClickIneFront}
                    >
                        {validIneFront ? "Cambiar" : "Seleccionar"}
                    </Button>
                </Grid>
            </Grid>
            <input id={'file-inefront'} style={{display: "none"}} type="file" name={"Imagen"} onChange={setIneFront}/>
        </Grid>
        {
            !oneCase ? <Grid item xs={12} md={5}>
                <Grid container justify={'center'} spacing={1}>
                    <Grid item xs={9} className={classes.noContainerCenter}>
                        <img className={classes.idSize}
                             src={validInePost ? inePost :
                                 type === "0000" ? InePostDefault :
                                     type === "1111" ? PermisoPostDefault : CedulaFrontDefault
                             }
                             alt="Ine frontal"/>
                    </Grid>
                    <Grid item xs={9} className={classes.noContainerCenter}>
                        <Button
                            color={!validInePost ? 'primary' : ''}
                            variant="contained"
                            onClick={handleClickInePost}
                        >
                            {validInePost ? "Cambiar" : "Seleccionar"}
                        </Button>
                    </Grid>
                </Grid>
                <input id={'file-inepost'} style={{display: "none"}} type="file" name={"Imagen"}
                       onChange={setInePost}/>
            </Grid> : ''
        }
        <Grid item xs={12}>
            <Typography variant={"body2"} align={"center"}>
                Las imagenes no deben pesar mas de 2 Mb
            </Typography>
        </Grid>
        <AlertSnackBar open={openError} onClose={closeIneFrontError} severity={'error'} autoHideDuration={4000}>
            Error en el formato de imagen
        </AlertSnackBar>
    </Grid>
}
