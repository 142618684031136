import {useState} from "react";

export const useFileValidator = (formatsToValidate) => {
    const [file, setFile] = useState(null);
    const [valid, setValid] = useState(false);
    let flag = false;
    const checkFile = (inputElement) => {
        const ext = inputElement.target.value.split(".")[inputElement.target.value.split(".").length - 1];
        for (let i = 0; i < formatsToValidate.length; i++) {
            if (ext === formatsToValidate[i]) {
                flag = true;
            }
        }
        if (flag) {

            let file = inputElement.target.files[0];
            var max_width = inputElement.target.getAttribute('data-maxwidth');
            var max_height = inputElement.target.getAttribute('data-maxheight');


            /*if (file.size > 2048000) {
                alert("El archivo pesa mas de 2Mb");
                setValid(false);
                return;
            }*/

            let reader = new FileReader();
            reader.onloadend = function () {
                setFile(reader.result);
                setValid(true);
            }
            if (file) {
                reader.readAsDataURL(file);
            } else {
                setValid(false);
            }
        } else {
            setFile("NONE");
        }
        setValid(false);
    }
//CentOS Linux 7 x86_64 HVM EBS ENA 2002_01-b7ee8a69-ee97-4a49-9e68-afaee216db2e-ami-0042af67f8e4dcc20.4
    //aws-marketplace/CentOS Linux 7 x86_64 HVM EBS ENA 2002_01-b7ee8a69-ee97-4a49-9e68-afaee216db2e-ami-0042af67f8e4dcc20.4
    //ami-098f55b4287a885ba
    function toDataURL(src, callback) {
        var image = new Image(); // create Image object
        image.crossOrigin = 'Anonymous';
        image.onload = function () {
            var canvas = document.createElement('canvas'); //initialize canvas
            var context = canvas.getContext('2d');
            let height = this.naturalHeight;
            let width = this.naturalWidth;
            let max_width = this.naturalWidth / 100 * 50;
            let max_height = this.naturalHeight / 100 * 50;

            if (width > height) {
                if (width > max_width) {
                    //height *= max_width / width;
                    height = Math.round(height *= max_width / width);
                    width = max_width;
                }
            } else {
                if (height > max_height) {
                    //width *= max_height / height;
                    width = Math.round(width *= max_height / height);
                    height = max_height;
                }
            }

            canvas.height = height;// this.naturalHeight;
            canvas.width = width;// this.naturalWidth;
            context.drawImage(this, 0, 0, width, height);
            var dataURL = canvas.toDataURL('image/jpeg');
            callback(dataURL);
        };
        image.src = src;
    }

    function resizeMe(img, max_height, max_width) {

        var canvas = document.createElement('canvas');

        var width = img.width;
        var height = img.height;

        // calculate the width and height, constraining the proportions
        if (width > height) {
            if (width > max_width) {
                //height *= max_width / width;
                height = Math.round(height *= max_width / width);
                width = max_width;
            }
        } else {
            if (height > max_height) {
                //width *= max_height / height;
                width = Math.round(width *= max_height / height);
                height = max_height;
            }
        }

        // resize the canvas and draw the image data into it
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        // preview.appendChild(canvas); // do the actual resized preview

        return canvas.toDataURL("image/jpeg", 0.7); // get the data from canvas as 70% JPG (can be also PNG, etc.)

    }

    const reset = () => {
        setFile(null);
        setValid(false);
    }
    return [file, valid, checkFile, reset];
}
