import React, {useEffect, useState} from "react";
import {
    Button,
    CircularProgress,
    Grid,
    Select,
    TextField,
    Typography,
    makeStyles,
    InputLabel,
    MenuItem, TableContainer, withStyles, TablePagination, TableFooter, FormControlLabel, FormGroup, Checkbox
} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {XEL_COLORS} from "../../constants";
import axios from "axios";
import {Alert} from "@material-ui/lab";
import Snackbar from "@material-ui/core/Snackbar";
import Prepago from "./Prepago";
import USER_DETAIL from "../../redux/Actions/USERDETAIL";
import {Axios} from "../../constants";
import FormControl from "@material-ui/core/FormControl";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
export const Reportes = () => {
    const options = [
        {
            name: "Fecha",
            value: 1010
        },
        {
            name: "Número",
            value: 2020
        }
    ]
    const classes = useStyles();
    const [value, setValue] = useState(options[0].value);
    const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));
    const [rows, setRows] = useState([]);
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const [dates, setDates] = useState({
        start: "",
        end: ""
    });
    const [total, setTotal] = useState(0);
    const [resetRequired, setResetRequired] = useState(false);
    const [startIn, setStarin] = useState(0);
    const [importe, setImporte] = useState(30);
    const [loading, setLoading] = useState(false);
    const [msisdn, setMsisdn] = useState("");
    const [filtros, setFiltros] = useState({
        fechas: true,
        importe: false,
        numero: false
    })
    let axios = Axios();
    useEffect(() => {
        let defaultDate = new Date();
        let date = defaultDate.getDate();
        if (date < 10)
            date = `0${date}`;
        let month = defaultDate.getMonth() + 1;
        if (month < 10)
            month = `0${month}`;
        let defDate = `${defaultDate.getFullYear()}-${month}-${date}`;

        setDates({
            start: defDate,
            end: defDate
        })
    }, [])

    const handleDates = (e) => {
        let fecs = dates;
        let id = e.target.id;
        switch (id) {
            case "date-start":
                fecs.start = e.target.value;
                break;
            case "date-end":
                fecs.end = e.target.value;
                break;
            default:
                return;

        }
        setResetRequired(true);
        setStarin(0);
        setDates(fecs);
    }
    const validFilters = () => {
        let filter = {
            dates
        };
        if (filtros.importe) {
            if (importe < 30) {
                window.alert("El importe minimo es de 30");
                return;
            }
            filter = {
                ...filter,
                importe
            }
        }
        if (filtros.numero) {
            if (msisdn.toString().split("").length !== 10) {
                window.alert("DN no válido");
                return;
            }
            filter = {
                ...filter,
                msisdn
            }
        }
        return filter;
    }
    const search = () => new Promise((resolve, reject) => {
        let filter = validFilters();
        axios.post("distributor/reports/recargas/list", {
            start: startIn,
            filter
        }).then(res => {

            resolve(res);
        }).catch(err => {

            reject(err);
        })
    })
    const handleReport = () => {
        let filter = validFilters();

        let email = window.prompt("Introduce el correo al que deseas enviar el reporte");

        if (!email || email === "") {
            window.alert("Introduce un correo valido");
            return;
        }
        setLoading(true);
        axios.post("distributor/reports/recargas/report", {
            start: startIn,
            filter,
            email
        }).then(res => {
            console.log(res.data);
            if (res.data.error) {
                window.alert(res.data.error_description);
                setLoading(false);
                return;
            }
            window.alert("Tu reporte se esta generando, se enviara en cuanto se complete");
            setLoading(false);
        }).catch(err => {
            window.alert("Ocurrio un error al procesar tu petición verifica si tu correo o los parametros, puedes intentarlo nuevamente, si el problema persiste intenta reiniciar sesión");
            setLoading(false);
        })

    }

    const handleSearch = () => {
        setLoading(true);
        search().then(res => {
            let response = res.data;
            if (response.error) {
                window.alert(response.error_description);
                setLoading(false);
                return;
            }
            setStarin(response.next);
            setTotal(response.total);
            setRows(response.elements);
            setLoading(false);
        }).catch(err => {
            console.error(err);
        })
    }
    const handleAttach = () => {
        setLoading(true);
        search().then(res => {
            let response = res.data;

            if (response.error) {
                window.alert(response.error_description);
                setLoading(false);
                return;
            }
            setStarin(response.next);
            setTotal(response.total);
            let befRows = rows;
            console.log(befRows)
            for (let i = 0; i < response.elements.length; i++) {
                befRows.push(response.elements[i]);
            }
            setRows(befRows);
            setLoading(false);
        }).catch(err => {
            console.error(err);
            setLoading(false);
        })
    }
    // const [options, setOptions] = useState(["Fecha", "Número"]);
    return <Grid container>
        <Grid item xs={12}>
            <Grid container direction={"column"}>
                <Grid item xs={12}>
                    <Typography variant={"body2"}>
                        Filtros
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={true}
                                    // checked={state.checkedB}
                                    //   onChange={handleChange}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            disabled={true}
                            label="Fecha"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={filtros.importe}
                                    onChange={() => {
                                        setFiltros({
                                            ...filtros,
                                            importe: !filtros.importe
                                        })
                                    }}
                                    name="importeCheck"
                                    color="primary"
                                />
                            }
                            label="Importe"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={filtros.numero}
                                    onChange={() => {
                                        setFiltros({
                                            ...filtros,
                                            numero: !filtros.numero
                                        })
                                    }}
                                    name="DNCheck"
                                    color="primary"
                                />
                            }
                            label="DN"
                        />
                    </FormGroup>
                </Grid>
            </Grid>
        </Grid>
        <Grid xs={12} className={"options-report"}>
            <Grid container justify={"center"}>
                <Grid item xs={12} md={4} lg={4} style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    paddingLeft: "1%"
                }}>
                    <TextField

                        id={'date-start'}
                        label="Desde"
                        type="date"
                        //  className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}

                        onChange={handleDates}
                    />
                    <TextField
                        id={'date-end'}
                        label="Hasta"
                        type="date"
                        //  className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}

                        onChange={handleDates}
                    />

                </Grid>
                {
                    filtros.importe && <Grid item xs={10} md={3} lg={2}>
                        <TextField
                            id={'amount-tosearch'}
                            label="Importe"
                            type="number"
                            defaultValue={30}
                            //defaultValue={defaultDate}
                            //  className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                min: "30",
                                max: "500"
                            }}
                            onChange={e => setImporte(e.target.value)}
                        />
                    </Grid>
                }
                {
                    filtros.numero && <Grid item xs={10} md={3} lg={2}>
                        <TextField
                            id={'dn-filter'}
                            label="DN"
                            type="text"
                            //defaultValue={30}
                            //defaultValue={defaultDate}
                            //  className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                min: "30",
                                max: "500"
                            }}
                            onChange={e => {
                                const val = e.target.value;
                                if (isNaN(val)) {
                                    return;
                                }
                                if (val.toString().split('').length > 10) {
                                    return;
                                }
                                setMsisdn(val);
                            }
                            }
                            value={msisdn}
                        />
                    </Grid>
                }
                <Grid item xs={4} md={3}
                      style={{display: "flex", justifyContent: "space-around", alignItems: "center"}}>
                    <Button variant={"contained"} onClick={handleSearch}>
                        <Typography variant={"subtitle2"}>
                            Buscar
                        </Typography>
                    </Button>
                    <Button variant={"contained"} onClick={handleReport}>
                        <Typography variant={"subtitle2"}>
                            Generar reporte
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
        <Grid xs={12} className={"table-report"} style={{marginTop: "1%"}}>
            <TableContainer component={Paper}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>
                                <Typography variant={"body2"}>ID</Typography>
                            </StyledTableCell>
                            <StyledTableCell align="right">
                                <Typography
                                    variant={"body2"}>Número</Typography>
                            </StyledTableCell>
                            <StyledTableCell align="right">
                                <Typography
                                    variant={"body2"}>Producto</Typography>
                            </StyledTableCell>
                            <StyledTableCell align="right">
                                <Typography
                                    variant={"body2"}>Precio</Typography>
                            </StyledTableCell>
                            <StyledTableCell align="right">
                                <Typography variant={"body2"}>Fecha de
                                    compra</Typography>
                            </StyledTableCell>
                            <StyledTableCell align="right">
                                <Typography variant={"body2"}>Fecha de
                                    expiración</Typography>
                            </StyledTableCell>
                            <StyledTableCell align="right">
                                <Typography variant={"body2"}>Responsable</Typography>
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.length === 0 &&
                            <Typography align={"center"} variant={"subtitle2"}>No hay nada que mostrar.</Typography>}
                        {loading ? <>
                            <CircularProgress size={50} color={"primary"}/>
                        </> : rows.map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell component="th" scope="row">
                                    {row.id}
                                </StyledTableCell>
                                <StyledTableCell align="right">{row.msisdn}</StyledTableCell>
                                <StyledTableCell align="right">{row.producto}</StyledTableCell>
                                <StyledTableCell align="right">{row.precio}</StyledTableCell>
                                <StyledTableCell align="right">{row.fecha_inicio}</StyledTableCell>
                                <StyledTableCell align="right">{row.fecha_fin ? row.fecha_fin : "N/A"}</StyledTableCell>
                                <StyledTableCell align="right">{row.responsable}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                    <TableFooter>

                    </TableFooter>
                </Table>
            </TableContainer>
            <Grid container spacing={1} style={{padding: "2%"}}>
                {
                    rows.length > 0 && <Grid item xs={6}>
                        <Typography variant={"body2"} style={{fontWeight: "700"}}>
                            Total: {total}
                        </Typography>
                    </Grid>
                }
                {
                    startIn > 0 && <Grid item xs={6}>
                        <Button variant={"contained"} color={"secondary"} onClick={handleAttach}>
                            <Typography variant={"subtitle2"}>
                                Siguiente
                            </Typography>
                        </Button>
                    </Grid>
                }
            </Grid>
        </Grid>
    </Grid>
}

export default function Recargas() {
    let axios = Axios();
    const details = useSelector(state => state.detail);
    let url = `http://localhost:3000`;
    let dispatch = useDispatch();

    const [phone, setPhone] = useState("");
    const [loading, setLoading] = useState(false);
    const onlyNumber = /^[0-9]+$/
    const [status, setStatus] = useState("SEARCH");
    const [modality, setModality] = useState(false);
    const [reportesView, setReportesView] = useState(false);
    const [toPay, setToPay] = useState(0);
    const [payData, setPayData] = useState({
        loaded: false,
        details: {}
    });
    const [postPayType, setPostPayType] = useState("");
    const [buycash, setBuyCash] = useState({
        open: false,
        amount: 0,
        procesed: false,
    })
    const [params, setParams] = useState({
        msisdn: phone,
        product: 0
    });
    const [planes, setPlanes] = useState({
        loading: true,
        planes: []
    });
    const [alert, setAlert] = useState({
        open: false,
        message: "",
        severity: "success"
    });
    const [reportsOpen, setReportsOpen] = useState(false);
    useEffect(() => {
        if (planes.planes.length === 0) {
            window.scrollTo(0, 0);
        }
    }, [planes]);

    const handleChange = e => {
        setAlert({
            ...alert,
            open: false
        });
        const value = e.target.value;
        if (onlyNumber.test(value)) {
            if (value.split('').length > 10)
                return;
            setPhone(value);
        } else if (phone.split('').length === 1) {
            setPhone(value);
        }
    }
    const handleChangeAmount = e => {
        setAlert({
            ...alert,
            open: false
        });

        const value = e.target.value;
        if (value.split("").length < buycash.amount.toString().split("").length)
            setBuyCash({
                ...buycash,
                amount: value
            })
        if (onlyNumber.test(value)) {
            setBuyCash({
                ...buycash,
                amount: value
            })
        } else if (phone.split('').length === 1) {
            setBuyCash({
                ...buycash,
                amount: value
            })
        }
    }
    const xelCashBuy = () => {
        if (parseInt(buycash.amount) < 100) {
            setAlert({
                open: true,
                message: "El monto debe ser mayor a 100",
                severity: "error",
            });
            return
        }
        setLoading(true);
        let token = window.localStorage.getItem('token');
        console.log("token", token);

        axios.post(`/distributor/r/buy.xelcash`, {
            amount: parseInt(buycash.amount)
        }, {
            headers: {
                "auth-token": token
            }
        }).then(res => {
            let response = res.data;
            if (response.error) {
                setAlert({
                    open: true,
                    message: response.error_description,
                    severity: "error",
                });
                return;
            }
            setLoading(false);
            setBuyCash({
                ...buycash,
                procesed: true
            })
            console.log(response);

        }).catch(err => {
            console.log(err);
            setAlert({
                open: true,
                message: "Número invalido",
                severity: "error",
            });
        })
    }

    const checkIfSkipRecharge = (token) => new Promise(async(resolve, reject) => {
	try {
	    var { data: { match } }  = await axios({
		method: 'GET',
		url: '/distributor/r/check.last.hour',
		params,
		headers: {
		    "auth-token": token,
		}
	    })	
	} catch (e) {
	    reject(e)
	}
	setLoading(false)
	const lastHourWarning = `
	    El número ingresado tuvo una recarga del mismo
	    producto hace menos de una hora, desea continuar?
	`
	console.log( 'match:', match, 'bool:', match ? 't' : 'f' )
	if (match) {
	    if(window.confirm(lastHourWarning)) // proceed?
		resolve(false) // yes, proceed, so, no skip
	    else resolve(true) // don't proceed, skip
	}
	// not recharged on last hour, no skip, proceed
	else resolve(false)
    })

    const comprar = async () => {
        if (loading)
            return;
        if (!confirmDn())
            return;
        setLoading(true);
        let token = window.localStorage.getItem('token');

	// if (await checkIfSkipRecharge(token))
	//     return

        axios.post(`/distributor/r/buy.recharge`, params, {
            headers: {
                "auth-token": token
            }
        }).then(res => {
            let response = res.data;
            if (response.error) {
                setLoading(false)
                setAlert({
                    open: true,
                    message: response.error_description,
                    severity: "error",
                });
                return;
            }
            dispatch(USER_DETAIL({
                ...details,
                xelcash: response.rest
            }));
            setLoading(false);
            setStatus("SEARCH");
            setPlanes({
                loading: true,
                planes: []
            });
            setPayData({
                loaded: false,
                details: {}
            })
            setPhone("");
            setParams({
                msisdn: "",
                product: 0
            });
            setAlert({
                open: true,
                message: `¡Recarga exitosa!${response.price ? "Monto: $" + response.price : ""}`,
                severity: "success",
            });
        }).catch(err => {
            console.error(err);
            setAlert({
                open: true,
                message: "Número invalido",
                severity: "error",
            });
        })
    }
    const pagarMensualidad = () => {
        if (loading)
            return;
        if (!confirmDn())
            return;
        setLoading(true);
        axios.post("/distributor/r/pay.mensuality", {
            msisdn: phone,
            pay: toPay
        }).then(res => {
            setLoading(false);
            let response = res.data;
            if (response.error) {
                setAlert({
                    open: true,
                    message: response.error_description,
                    severity: "error",
                });
                return;
            }
            setAlert({
                open: true,
                message: response.message,
                severity: "success",
            });
            setToPay(0);
            setPhone("");
            setPayData({
                loaded: false,
                details: {}
            });
        }).catch(err => {
            console.log(err);
            setLoading(false);
            setAlert({
                open: true,
                message: "Hubo un problema conectando con el servidor",
                severity: "error",
            });
        });
    }

    const confirmDn = () => {
        let msisdn = window.prompt("Repite el número para confirmar tu recarga");
        if (msisdn !== phone) {
            window.prompt("Los número no coinciden");
            return false;
        }
        return true;
    }
    const search = () => {
        if (phone.split("").length === 0) {
            setAlert({
                open: true,
                message: "Numero no válido",
                severity: "error",
            })
            return;
        }
        setLoading(true);

        setAlert({
            ...alert,
            open: false
        });

        axios.get(`/clients/pays/${phone}`).then(res => {
            let response = res.data;
            setLoading(false);
            if (response.error) {
                setAlert({
                    open: true,
                    message: response.error_description,
                    severity: "error",
                });
                return;
            }
            setStatus("BUY");
            setPayData({
                loaded: true,
                details: response
            });
            if (response.saldo > 0)
                setToPay(response.saldo);

        }).catch(err => {
            setLoading(false);
            setAlert({
                open: true,
                message: "Verifica tu conexión a internet e intenta de nuevo, si el problema persiste intenta reiniciar sesión",
                severity: "error",
            })
        })
        /* axios.get(`/distributor/r/${phone}/checker`).then(res => {
             let response = res.data;
             if (response.error) {
                 setAlert({
                     open: true,
                     message: response.error_description,
                     severity: "error",
                 });
                 setLoading(false);
                 return;
             }
             setModality(!response.mifi);
             axios.get(`/clients/pays/${phone}`).then(res => {
                 let response = res.data;
                 setLoading(false);
                 setStatus("BUY");
                 setPlanes({
                     loading: false,
                     planes: response.planes
                 });
             }).catch(err => {
                 console.log(err)
                 setLoading(false);
                 setAlert({
                     open: true,
                     message: "Verifica tu conexión a internet e intenta de nuevo, si el problema persiste intenta reiniciar sesión",
                     severity: "error",
                 })
             });
         }).catch(err => {
             console.log(err);
             setLoading(false);
             setAlert({
                 open: true,
                 message: "Verifica tu conexión a internet o intenta de nuevo",
                 severity: "error",
             })
         })*/
    }

    return <Grid container>
        <Grid xs={12} style={{display: "flex", justifyContent: "space-between", padding: "2%"}}>
            <Typography>
                Recargas inXel
            </Typography>
            <Grid item xs={10} md={6}
                  style={{display: "flex", justifyContent: "center", alignItems: "flex-end", flexDirection: "column"}}>
                <Typography>
                    XelCash disponible: ${details.xelcash}
                </Typography>
                <Grid item style={{padding: "1%", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                    {
                        (buycash.open || reportsOpen) &&
                        <Button style={{margin: "1%"}} disabled={loading} onClick={() => {
                            if (loading)
                                return;
                            setBuyCash({
                                ...buycash,
                                open: false
                            });
                            setReportsOpen(false);
                        }} color={"primary"} variant={"contained"}>
                            <Typography variant={"body2"}>
                                Recargas
                            </Typography>
                        </Button>
                    }
                    {
                        details.permisos.distributor &&
                        <Button style={{margin: "1%"}} disabled={loading} onClick={() => {
                            if (loading)
                                return;
                            setBuyCash({
                                ...buycash,
                                open: true
                            });
                            setReportsOpen(false);
                        }} color={"primary"} variant={"contained"}>
                            <Typography variant={"body2"}>
                                Comprar XELCASH
                            </Typography>
                        </Button>
                    }
                    {
                        (details.permisos.distributor && !reportsOpen) &&
                        <Button style={{margin: "1%"}} disabled={loading} onClick={() => {
                            if (loading)
                                return;
                            else {
                                setReportsOpen(true);
                            }
                        }} color={"primary"} variant={"contained"}>
                            <Typography variant={"body2"}>
                                Abrir reportes
                            </Typography>
                        </Button>
                    }
                </Grid>
            </Grid>
        </Grid>
        {
            reportsOpen && <Reportes/>
        }
        {
            buycash.open && <Grid item xs={12}>
                <Grid container justify={"center"} direction={"column"} alignItems={"center"} style={{width: "100%"}}>
                    <Grid item xs={12} sm={6} md={4} style={{width: "100%"}}>
                        {
                            buycash.procesed ?
                                <Typography style={{textAlign: "center"}}>Se enviaron las instrucciones de pago a tu
                                    correo</Typography> :
                                <TextField label={'Inserta el monto a recargar'} value={buycash.amount}
                                           onChange={handleChangeAmount}
                                           fullWidth/>
                        }
                    </Grid>
                    <Grid item xs={6} md={4} style={{marginTop: "1%", display: "flex", justifyContent: "center"}}>
                        {
                            !buycash.procesed && <Button
                                disabled={loading}
                                onClick={() => {
                                    if (loading)
                                        return
                                    if (buycash.procesed) {
                                        setBuyCash({
                                            open: false,
                                            procesed: false,
                                            amount: 0
                                        })
                                    } else {
                                        xelCashBuy()
                                    }
                                }}
                                style={{
                                    color: "#FEFEFE",
                                    margin: "1%",
                                }}
                                color={"primary"}
                                variant={"contained"}>

                                Continuar
                                {
                                    loading && <CircularProgress size={10}/>
                                }
                            </Button>
                        }
                        <Button
                            disabled={loading}
                            onClick={() => {
                                if (loading)
                                    return
                                setBuyCash({
                                    open: false,
                                    procesed: false,
                                    amount: 0
                                })
                            }}
                            style={{color: "#FEFEFE", margin: "1%"}}
                            color={"secondary"}
                            variant={"contained"}>
                            Volver
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        }
        {
            !buycash.open && !reportsOpen ? <Grid item xs={12}>
                <Grid container spacing={2} justify={"center"} direction={"column"} alignItems={"center"}
                      style={{width: "100%"}}>
                    <Grid item xs={12}>
                        {
                            loading && <CircularProgress/>
                        }
                    </Grid>
                    <Grid item xs={12} sm={6} md={5} style={{width: "100%"}}>
                        <TextField disabled={loading || payData.loaded} label={'Inserta número inXel'}
                                   value={phone} onChange={handleChange} fullWidth/>
                    </Grid>
                    {
                        !loading && payData.loaded ? <Grid item xs={10} md={6}>
                            <Grid container spacing={2} justify={"center"}>
                                {
                                    payData.details.postpago && payData.details.saldo > 0 && <Grid item xs style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        marginBottom: "2%",
                                        alignItems: "center",
                                        flexDirection: "column"
                                    }}>
                                        <Typography variant={"body2"} style={{textAlign: "center"}}>
                                            Actualmente la linea cuenta con un saldo de {payData.details.saldo}
                                        </Typography>
                                        <Typography variant={"body2"} style={{textAlign: "center"}}>
                                            ¿Desea pagar el saldo?
                                        </Typography>
                                        <Button color={"primary"} variant={"contained"}
                                                style={{marginTop: "1%", marginBottom: "1%"}} onClick={pagarMensualidad}
                                        >
                                            Pagar ${payData.details.saldo}
                                        </Button>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    {
                                        payData.details.planes.length > 0 && (payData.details.postpago && payData.details.saldo > 0 ?
                                            <Typography style={{fontSize: 12}} align={'center'}>
                                                o puedes realizar una recarga adicional
                                            </Typography> : <Typography style={{fontSize: 12}} align={'center'}>
                                                Selecciona plan
                                            </Typography>)
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        payData.details.planes.length > 0 ?
                                            <Grid spacing={2} container justify={"center"}>
                                                {
                                                    payData.details.planes.map((plan, index) => {
                                                        return <Grid item xs={12} key={index}>
                                                            <Prepago
                                                                onClick={() => {
                                                                    if (loading)
                                                                        return;

                                                                    setParams({
                                                                        msisdn: phone,
                                                                        product: parseInt(plan.id_product)
                                                                    })
                                                                    //   dispatch(Actions.SET_PRE_BUYDET(plan.id_product, plan.price, plan.name));
                                                                }
                                                                }
                                                                rePreplan={payData.details.preplan}
                                                                onContinue={comprar}
                                                                selected={params.product.toString() === plan.id_product.toString()}
                                                                data={plan}/>
                                                        </Grid>
                                                    })
                                                }
                                            </Grid> :
                                            (!payData.details.postpago || payData.details.saldo === 0) &&
                                            <Grid item xs={12}>
                                                <Typography variant={"body2"}>
                                                    No hay planes disponibles
                                                </Typography>
                                            </Grid>
                                    }
                                </Grid>
                            </Grid>


                        </Grid> : ""
                    }
                    {
                        !planes.loading ? <Grid item xs={10} md={6} style={{marginTop: '3%'}}>
                            <Grid container spacing={2} justify={'center'}>
                                {
                                    planes.loading ?
                                        <Grid item xs={12}>
                                            <Typography>
                                                Cargando planes <CircularProgress size={10}/>
                                            </Typography>
                                        </Grid> : planes.planes.length > 0 ?
                                            <Grid container spacing={3} justify={'center'}>
                                                <Grid item xs={6}>
                                                    <Typography style={{fontSize: 12}} align={'center'}>
                                                        Selecciona plan
                                                    </Typography>
                                                </Grid>
                                                {
                                                    planes.planes.map(plan => <Grid key={plan.id_product} item xs={12}>
                                                        <Prepago
                                                            onClick={() => {
                                                                if (loading)
                                                                    return;

                                                                setParams({
                                                                    msisdn: phone,
                                                                    product: parseInt(plan.id_product)
                                                                })
                                                                //   dispatch(Actions.SET_PRE_BUYDET(plan.id_product, plan.price, plan.name));
                                                            }
                                                            }

                                                            onContinue={comprar}
                                                            selected={params.product.toString() === plan.id_product.toString()}
                                                            data={plan}/>
                                                    </Grid>)
                                                }
                                                {
                                                    modality && <Grid item xs={10}>
                                                        <Typography align={'center'} style={{fontSize: 11}}>
                                                            * Política de uso justo: Superando los primeros 20GB, cuentas
                                                            con
                                                            20GB
                                                            adicionales para
                                                            seguir
                                                            navegando con velocidad limitada a 512 Kbps.
                                                        </Typography>
                                                        <Typography align={'center'} style={{fontSize: 11}}>
                                                            ** Política de uso justo: Superando los primeros 2GB, cuentas
                                                            con
                                                            2GB
                                                            adicionales para
                                                            seguir
                                                            navegando con velocidad limitada a 512 Kbps.
                                                        </Typography>
                                                        <Typography align={'center'} style={{fontSize: 11}}>
                                                            *** Política de uso justo: Superando los primeros 5GB, cuentas
                                                            con
                                                            5GB
                                                            adicionales para
                                                            seguir
                                                            navegando con velocidad limitada a 512 Kbps.
                                                        </Typography>
                                                    </Grid>
                                                }
                                                }
                                            </Grid> : <Grid item xs={12}>
                                                <Typography align={'center'}>
                                                    Oh!, parece que no hay planes disponibles contactanos para solucionarlo
                                                    o
                                                    intenta <a href="#" onClick={() => {
                                                    window.location.reload();
                                                }}>recargar</a> la pagina.
                                                </Typography>
                                            </Grid>
                                }
                            </Grid>
                        </Grid> : ""
                    }
                    <Grid item xs={6} md={4} style={{marginTop: "1%", display: "flex", justifyContent: "center"}}>
                        <Button
                            disabled={loading}
                            onClick={() => {
                                if (loading)
                                    return
                                if (status === 'SEARCH')
                                    search()
                                else {
                                    setPlanes({
                                        loading: true,
                                        planes: []
                                    })
                                    setStatus("SEARCH");
                                    setPayData({
                                        loaded: false,
                                        details: {}
                                    })
                                }
                            }}
                            style={{color: "#FEFEFE", marginTop: "1%"}}
                            color={"secondary"}
                            variant={"contained"}>
                            {
                                status === "SEARCH" ? "Continuar" : "Volver"
                            }
                        </Button>
                    </Grid>
                </Grid>
            </Grid> : ""
        }
        <Grid item xs={12}>
            <Snackbar open={alert.open} autoHideDuration={10000}
            >
                <Alert style={{fontSize: 23, fontWeight: "700"}} onClose={() => setAlert({...alert, open: false})}
                       severity={alert.severity}>
                    {alert.message}
                </Alert>
            </Snackbar>
        </Grid>
    </Grid>
}
