import {useMediaQuery} from "@material-ui/core";

export default function useResponsiveValues(xs, sm=null, md=null, lg=null, xl=null, xxl=null) {
    const matchesXs = useMediaQuery('(min-width:0px)');
    const matchesSm = useMediaQuery('(min-width:576px)');
    const matchesMd = useMediaQuery('(min-width:768px)');
    const matchesLg = useMediaQuery('(min-width:992px)');
    const matchesXl = useMediaQuery('(min-width:1200px)');
    const matchesXxl = useMediaQuery('(min-width:1400px)');

    if (matchesXxl)
        return xxl ? xxl : xl ? xl : lg ? lg : md ? md : sm ? sm : xs
    if (matchesXl)
        return xl ? xl : lg ? lg : md ? md : sm ? sm : xs
    if (matchesLg)
        return lg ? lg : md ? md : sm ? sm : xs
    if (matchesMd)
        return md ? md : sm ? sm : xs
    if (matchesSm)
        return sm ? sm : xs
    if (matchesXs)
        return xs
}