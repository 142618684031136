/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import {createBrowserHistory} from "history";
import {Router, Route, Switch, Redirect} from "react-router-dom";

// core components
import Admin from "layouts/Admin.js";
import RTL from "layouts/RTL.js";
import NotLogged from "./views/NoLogged";
import "assets/css/material-dashboard-react.css?v=1.8.0";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import {ThemeProvider} from "@material-ui/styles";
import {XEL_FONT, XEL_COLORS, OPENPAY, Axios} from './constants/index';

import './index.css';
import Loading from "./views/Loading";
import PaymentRequest from "./views/PaymentRequest";
import CustomerDataRequest from "./components/CustomerDataRequest";
import {Provider, useDispatch, useSelector} from "react-redux"; //Estilos
import myStore from "./redux/Reducers";
import LOG_IN from "./redux/Actions/LOGIN";
import RecoveryVerify from "./views/RecoveryVerify";
import useQueryParams from "./hooks/useQueryParams";
import USER_DETAIL from "./redux/Actions/USERDETAIL";
import Escanner from "./components/Escanner";
import io from 'socket.io-client';
import NOTIFICATION from "./redux/Actions/NOTIFICATION";
import AlertSnackBar from "./components/AlertSnackBar";
import "egalink-toasty.js";
import "egalink-toasty.js/src/toasty.css";
import Sound1 from 'egalink-toasty.js/dist/sounds/success/1.mp3';
import Sound2 from 'egalink-toasty.js/dist/sounds/error/1.mp3';
import Sound3 from 'egalink-toasty.js/dist/sounds/warning/1.mp3';
import Sound4 from 'egalink-toasty.js/dist/sounds/info/1.mp3';
import LoginForm from "./views/Login";
//Tema inxel
const inXelTheme = createMuiTheme({
    palette: {
        primary: {
            main: XEL_COLORS.primary
        },
        secondary: {
            main: XEL_COLORS.secondary
        },
    },
    typography: {
        fontFamily: XEL_FONT.name,
        h1: {
            fontWeight: XEL_FONT.bold_weight,
         //   fontSize: 36

        },
        h2: {
            fontWeight: XEL_FONT.semi_bold_weight,
           // fontSize: 33
        },
        h3: {
            fontWeight: XEL_FONT.semi_bold_weight,
        //    fontSize: 30
        },
        h4: {
            fontWeight: XEL_FONT.semi_bold_weight,
            //fontSize: 27
        },
        h5: {
            fontWeight: XEL_FONT.semi_bold_weight,
          //  fontSize: 24
        },
        h6: {
            fontWeight: XEL_FONT.semi_bold_weight,
           // fontSize: 21
        },
        body1: {
            fontWeight: XEL_FONT.regular_weight,
            //fontSize: 18
        },
        body2: {
            fontWeight: XEL_FONT.regular_weight,
         //   fontSize: 15
        },
        button: {
            fontWeight: XEL_FONT.regular_weight,
            textTransform: 'none',
          //  fontSize: 18,
        },
        especial: {
            fontWeight: XEL_FONT.regular_weight,
           // fontSize: 50,
            textAlign: 'center'
        }
    }
});
const hist = createBrowserHistory();


const Start = () => {

    const login = useSelector(state => state.login);
    const session = useSelector(state => state.detail);
    const dispatch = useDispatch();
    const [loaded, setLoaded] = useState(login.logged);
    const [logged, setLogged] = useState(login.logged);
    const [socket, setSocket] = useState(null);
    const auth = useQueryParams('auth');
    const axios = Axios();
    const url = process.env.NODE_ENV === 'development' ? "http://localhost:3000" : "https://api.inxel.mx";
    let toast = new window.Toasty({
        enableSounds: true,
        progressBar: true,
        duration: 30000,
        sounds: {
            success: Sound1,
            error: Sound2,
            warning: Sound3,
            info: Sound4
        },
        autoClose: false
    });
    useEffect(() => {

        let sandbox = false;

        // eslint-disable-next-line no-undef
        OpenPay.setId(sandbox ? OPENPAY.SANDBOX_ID : OPENPAY.PRODUCTION_ID);

        // eslint-disable-next-line no-undef
        OpenPay.setApiKey(sandbox ? OPENPAY.SANDBOX_API_KEY : OPENPAY.PRODUCTION_API_KEY);

        // eslint-disable-next-line no-undef
        OpenPay.setSandboxMode(sandbox);

        if (!login.logged) {//Requiere logeo
            //Verificar si existe un token guardado
            let token = auth && auth !== '' ? auth : window.localStorage.getItem('token');
            if (token) {
                axios.post('distributor/info', {}, {
                    headers: {
                        'auth-token': token
                    }
                }).then(res => {
                    window.localStorage.setItem('token', token);
                    setSocket(io(url));
                    dispatch(USER_DETAIL(res.data));
                    dispatch(LOG_IN({logged: true, token: token}));
                    setLogged(true);
                    setLoaded(true);
                }).catch(err => {
                    window.localStorage.removeItem('token');
                    setLoaded(true);
                })
            } else {
                setLoaded(true);
            }
        }

                    // setLogged(true);
    }, [])



    useEffect(() => {
        if (socket) {
            if (session)
                if (session.notification_id) {
                    socket.on("global_distributor_notifications", (msg) => {
                        switch (msg.severity) {
                            case "success":
                                toast.success(`${msg.message}\n${msg.date}`);
                                break;
                            case "error":
                                toast.error(`${msg.message}\n${msg.date}`);
                                break;
                            case "warning":
                                toast.warning(`${msg.message}\n${msg.date}`);
                                break;
                            case "info":
                                toast.info(`${msg.message}\n${msg.date}`);
                                break;
                            default:
                                return;
                        }
                    })
                    socket.on(session.notification_id, (msg) => {
                        dispatch(NOTIFICATION(msg));
                        switch (msg.severity) {
                            case "success":
                                toast.success(`${msg.message}\n${msg.date}`);
                                break;
                            case "error":
                                toast.error(`${msg.message}\n${msg.date}`);
                                break;
                            case "warning":
                                toast.warning(`${msg.message}\n${msg.date}`);
                                break;
                            case "info":
                                toast.info(`${msg.message}\n${msg.date}`);
                                break;
                            default:
                                return;

                        }
                    })
                }
        }
    }, [socket, session]);

    if (!loaded)
        return <ThemeProvider theme={inXelTheme}>
            <Router history={hist}>
                <Switch>
                    <Route path={'/pagar/:id_orden'} exact>
                        <CustomerDataRequest/>
                    </Route>
                    <Route path={'/activation'} exact>
                        <RecoveryVerify option={1}/>
                    </Route>
                    <Route>
                        <Loading/>
                    </Route>
                </Switch>
            </Router>
        </ThemeProvider>

    if (!logged)
        return <ThemeProvider theme={inXelTheme}>
            <Router history={hist}>
                <Switch>
                    <Route path={'/pagar/:id_orden'} exact>
                        <CustomerDataRequest/>
                    </Route>
                    <Route path={'/activation'} exact>
                        <RecoveryVerify option={1}/>
                    </Route>
                    <Route>
                        <LoginForm/>
                    </Route>
                </Switch>
            </Router>
        </ThemeProvider>

    return <>
        <Route path={'/recovery'} exact>
            <RecoveryVerify option={2}/>
        </Route>

        <Route path={'/activation'} exact>
            <RecoveryVerify option={1}/>
        </Route>
        <Route path={'/pagar/:id_orden'} exact>
            <PaymentRequest/>
        </Route>
        <Route path="/admin" component={Admin}/>
        <Redirect from="/" to="/admin/"/>
    </>
}

ReactDOM.render(<Provider store={myStore}>
        <ThemeProvider theme={inXelTheme}> <Router history={hist}>
            <Switch>
                <Start/>
            </Switch>
        </Router>
        </ThemeProvider>
    </Provider>
    , document.getElementById("root"))

/*ReactDOM.render(
,
document.getElementById("root")
);*/
