import React, {useEffect, useState} from "react";
import {Button, Grid} from "@material-ui/core";
import './css/style.css';
import Card1 from './assets/cards1.png';
import Card2 from './assets/cards2.png';
import Cvv from './assets/cvv.png';
import OpenpayLogo from './assets/openpay.png';
import RadioOn from './assets/radio_on.png';
import Security from './assets/security.png';
import TextInput from "../TextInput";
import NumberFormat from "react-number-format";
import AlertSnackBar from "../AlertSnackBar";

export default function OpenPayForm({amount, onSuccess}) {

    const [openError, setOpenError] = useState({
        open: false,
        message: ""
    });

    const defaultData = {
        name: '',
        card: '',
        cardFormated: '',
        email: '',
        feExp: '',
        feExpFormated: "",
        cvv: ""
    }
    const [data, setData] = useState(defaultData);

    useEffect(() => {
        console.log(data);
    }, [data])


    const onClose = () => {
        setOpenError(false);
    }
    const limit = (val, max) => {
        if (val.length === 1 && val[0] > max[0]) {
            val = '0' + val;
        }

        if (val.length === 2) {
            if (Number(val) === 0) {
                val = '01';

                //this can happen when user paste number
            } else if (val > max) {
                val = max;
            }
        }

        return val;
    }

    const cardExpiry = (val) => {
        let month = limit(val.substring(0, 2), '12');
        let year = val.substring(2, 4);

        return month + (year.length ? '/' + year : '');
    }

    const cvvNum = (val) => {
        let valid = limit(val.substring(0, 4), '9999');
        return valid;
    }

    return <Grid container>
        <Grid item xs={12}>
            <div className="bkng-tb-cntnt">
                <div className="pymnts">
                    <form action="#" method="POST" id="payment-form">
                        <input type="hidden" name="token_id" id="token_id"/>
                        <div className="pymnt-itm card active">
                            <Grid container>
                                <Grid item xs={12}>
                                    <h2>Tarjeta de crédito o débito</h2>
                                </Grid>
                            </Grid>
                            <Grid container component={'div'} className="pymnt-cntnt">
                                <Grid component={'div'} item xs={12} className="card-expl">
                                    <Grid container justify={'center'} spacing={2} style={{height: 'fit-content'}}>
                                        <Grid item xs={12} md={3} className="credit"
                                              style={{backgroundImage: `url(${Card1})`}}>
                                            <h4>Tarjetas de
                                                crédito</h4>
                                        </Grid>
                                        <Grid item xs={12} md className="debit"
                                              style={{backgroundImage: `url(${Card2})`}}>
                                            <h4>Tarjetas de
                                                débito</h4>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} style={{padding: '1%'}}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={11} md={6}>
                                            <TextInput
                                                name={'name'}
                                                error={false}
                                                placeHolder={'Nombre completo'}
                                                helperText={''}
                                                onChange={e => {
                                                    setData({
                                                        ...data,
                                                        name: e.target.value
                                                    });
                                                }}
                                                //   onChange={handleChange}>
                                            >
                                            </TextInput>
                                        </Grid>
                                        <Grid item xs={11} md={6}>
                                            <NumberFormat
                                                name={'card_number'}
                                                placeHolder={'Número de tarjeta'}
                                                error={false}
                                                helperText={''}
                                                // onChange={handleChange}
                                                format="#### #### #### ####"
                                                onValueChange={(values) => {
                                                    const {formattedValue, value} = values;
                                                    // formattedValue = $2,223
                                                    // value ie, 2223
                                                    //this.setState({profit: formattedValue})
                                                    setData({
                                                        ...data,
                                                        card: value,
                                                        cardFormated: formattedValue
                                                    })
                                                }}
                                                // mask="_"
                                                customInput={TextInput}
                                                //  value={tcontacto}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container style={{padding: '1%'}}>
                                        <Grid item xs={12}>
                                            <TextInput
                                                name={'email'}
                                                error={false}
                                                placeHolder={'Correo electrónico'}
                                                helperText={''}
                                                onChange={e => {
                                                    setData({
                                                        ...data,
                                                        email: e.target.value
                                                    })
                                                }}
                                                //   onChange={handleChange}>
                                            >
                                            </TextInput>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} style={{padding: '1%'}}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={11} md={4}>
                                            <NumberFormat
                                                name={'expd'}
                                                placeHolder={'Fecha de expiración'}
                                                error={false}
                                                helperText={''}
                                                // onChange={handleChange}
                                                format={cardExpiry}
                                                // mask="_"
                                                onValueChange={(values) => {
                                                    const {formattedValue, value} = values;
                                                    // formattedValue = $2,223
                                                    // value ie, 2223
                                                    //this.setState({profit: formattedValue})
                                                    setData({
                                                        ...data,
                                                        feExp: value,
                                                        feExpFormated: formattedValue
                                                    })
                                                }}
                                                customInput={TextInput}
                                                //  value={tcontacto}
                                            />
                                        </Grid>
                                        <Grid item xs={11} md={8}>
                                            <Grid container alignItems={'center'}>
                                                <Grid item xs={12} sm={8}>
                                                    <NumberFormat
                                                        name={'cvv'}
                                                        placeHolder={'Código de seguridad'}
                                                        error={false}
                                                        helperText={''}
                                                        // onChange={handleChange}
                                                        format={cvvNum}
                                                        // mask="_"
                                                        type={'password'}
                                                        onValueChange={(values) => {
                                                            const {formattedValue, value} = values;
                                                            // formattedValue = $2,223
                                                            // value ie, 2223
                                                            //this.setState({profit: formattedValue})
                                                            setData({
                                                                ...data,
                                                                cvv: value,
                                                            })
                                                        }}
                                                        customInput={TextInput}
                                                        //  value={tcontacto}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}
                                                      style={{display: 'flex', justifyContent: 'center'}}>
                                                    <img style={{width: '90%'}} src={Cvv} alt="Cvv"/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} className="openpay">
                                    <Grid container justify={'center'}>
                                        <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center'}}>
                                            <div className="logo"
                                                 style={{backgroundImage: `url(${OpenpayLogo})`}}>Transacciones
                                                realizadas vía:
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <div className="shield" style={{backgroundImage: `url(${Security})`}}>Tus
                                                pagos se
                                                realizan de forma segura con encriptación
                                                de 256 bits
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginTop: '2%',
                                    padding: '2%'
                                }}>
                                    <Button variant={"contained"} color={'primary'}>
                                        Pagar
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </div>
            </div>
        </Grid>
        <AlertSnackBar open={openError.open} onClose={onClose} severity={'error'} autoHideDuration={4000}>
            {
                openError.message
            }
        </AlertSnackBar>
    </Grid>
}