import React, {useEffect, useState} from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {useSelector} from "react-redux";
import {Axios} from "../../constants";
import {CircularProgress, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Indicator from "../../components/Indicator";
import Button from "@material-ui/core/Button";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const useStyles = makeStyles(styles);


export default function TableList() {
    const classes = useStyles();
    const session = useSelector(state => state.login);
    const [sells, setSells] = useState([]);
    const [sellsShow, setSellsShow] = useState([]);
    const [filter, setFilter] = useState('');
    const [loading, setLoading] = useState(true);
    const axios = Axios();
    const [next, setNext] = useState(0);
    const [hableNext, setHableNext] = useState(false);
    const [total, setTotal] = useState(0);
    useEffect(() => {
        axios.get(`/distributor/sells?start=${next}`,).then(res => {
      setSells([
          ...sells,
          ...res.data.sells
      ]);
            setLoading(false);
            setSellsShow([
                ...sellsShow,
                ...res.data.sells,
            ]);
            console.log("Primera carga", )
            if (res.data.next) {
                setNext(res.data.next);
                setHableNext(true)
            }
            setTotal(res.data.total);

        });
    }, []);
    useEffect(() => {
        let show = [];
        for (let i = 0; i < sells.length; i++) {
            let sell = sells[i];
            sell[11] = <Indicator stage={sell[11]}/>
            show.push(sell);
        }
        setSellsShow(show);
    }, [sells]);

    const handleNext = () => {
        setLoading(true);
        axios.get(`/distributor/sells?start=${next}`,).then(res => {
            setSells([
                ...sells,
                ...res.data.sells
            ]);
            setSellsShow([
                ...sellsShow,
                ...res.data.sells,
            ]);

            if (res.data.next) {
                setHableNext(true);
                setNext(res.data.next);
            } else
                setHableNext(false);
            setLoading(false);
        });
    }

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="primary">
                        <h3 className={classes.cardTitleWhite}>Ventas</h3>
                        <p className={classes.cardCategoryWhite}>
                            Aqui se detallan las ventas realizadas
                        </p>
                       <Grid container>
                           <Grid item xs={12} style={{display:"flex",alignItems:"center"}}>
                               {
                                   total>0 && <Typography variant={"body2"}>
                                       Mostrando {sellsShow.length} de {total}
                                   </Typography>
                               }
                               {
                                   (hableNext&&!loading) && <Button style={{marginLeft:5}} color={"secondary"} variant={"contained"}>
                                       <Typography variant={"body2"} onClick={handleNext}>
                                           Siguientes 10
                                       </Typography>
                                   </Button>
                               }
                           </Grid>
                       </Grid>
                    </CardHeader>
                    <CardBody>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Folio", "Cliente", 'Tipo', 'Modalidad', 'Producto', 'Dn', 'Dn provisional', "ICC", "IMEI", "Fecha", "Vendedor", 'Status']}
                            tableData={sellsShow}
                        />
                        {
                            loading ? <Grid container>
                                <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', marginTop: '1%'}}>

                                    <CircularProgress color={'secondary'}/>

                                </Grid>
                            </Grid> : ""
                        }
                    </CardBody>
                    {
                        (hableNext&&!loading) && <Button style={{marginLeft:5}} color={"primary"} variant={"contained"}>
                            <Typography variant={"body2"} onClick={handleNext}>
                                Siguientes 10
                            </Typography>
                        </Button>
                    }
                </Card>
            </GridItem>
        </GridContainer>
    );
}
