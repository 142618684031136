import {act} from "react-dom/test-utils";

const NotificationReducer = (state = [], action) => {
    switch (action.type) {
        case "NOTIFICATION":
            let nstate = state;
            nstate.push({
                ...action.payload,
                seen: false
            });
            return state = nstate;
        case "NOTIFICATIONS":
            return (state = action.payload);
        default:
            return state;
    }
};
export default NotificationReducer;
