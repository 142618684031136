import React, {useEffect, useState} from "react";

//import useQueryParams from "../../hooks/useQueryParams";
import {CircularProgress, Container, Grid, Input, InputLabel, Typography} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import {useLocation} from "react-router-dom";
import useQueryParams from "../../hooks/useQueryParams";
import {makeStyles} from "@material-ui/core/styles";
import TextInput from "../../components/TextInput";
import useResponsiveValues from "../../hooks/useResponsiveValues";
import Button from "@material-ui/core/Button";
import axios from "axios";
import {Axios} from "../../constants";


const useStyles = makeStyles({
    root: {
        height: '100%'
    }
})

export default function RecoveryVerify({option}) {
    //   const tk = useQueryParams('tk');
    const styles = useStyles();
    const auth = useQueryParams('auth_verify');
    const [loadedData, setLoadedData] = useState({
        loaded: false,
        status: "",
        data: {}
    });
    const {loaded, status, data} = loadedData;
    const [password, setPassword] = useState("");

    const text = useResponsiveValues(13, 13, 15, 17);
    const title = useResponsiveValues(13, 13, 18, 20);
    const axios = Axios();
    useEffect(() => {
        if (!auth || auth === '') {
            setLoadedData({
                ...loadedData,
                loaded: true,
                status: "NOTK",
            });
        } else {
            axios.post('distributor/token.validation/', {}, {
                headers: {
                    'auth-token': auth
                }
            }).then(res => {
                console.log(res);
                setLoadedData({
                    ...loadedData,
                    loaded: true,
                    status: 'OK',
                })
            }).catch(err => {
                if (err.status === 403) {
                    setLoadedData({
                        ...loadedData,
                        loaded: true,
                        status: 'N403'
                    });
                } else {
                    setLoadedData({
                        ...loadedData,
                        loaded: true,
                        status: 'UNK'
                    });
                }
            })
        }
    }, []);

    const onChange = e => {
        setPassword(e.target.value);
    }
    const onSubmit = e => {
        //e.defaultPrevented();
        e.preventDefault();
        setLoadedData({
            ...loadedData,
            loaded: false
        });
        if (option === 1) {
            axios.post('distributor/register/', {password}, {
                headers: {
                    'auth-token': auth
                }
            }).then(res => {
                // console.log(res);
                setLoadedData({
                    ...loadedData,
                    loaded: true,
                    status: 'ACTIVATED',
                })
            }).catch(err => {
                console.log(err);
                if (err.status === 403) {
                    setLoadedData({
                        ...loadedData,
                        loaded: true,
                        status: 'N403'
                    });
                } else {
                    setLoadedData({
                        ...loadedData,
                        loaded: true,
                        status: 'UNK'
                    });
                }
            })
        } else if (option === 2) {

        }

    }
    return <Grid className={styles.root} container justify={'center'} alignItems={'center'}>
        <Grid item xs={11}>
            <Grid container>
                {
                    !loaded ? <Grid item xs={12}>
                        <Typography
                            //        align={'center'}

                            style={{fontSize: text, display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                        >
                            Cargando... <CircularProgress/>
                        </Typography>
                    </Grid> : ""
                }
                {
                    loaded && status === 'OK' ? <>
                        <Grid item xs={12}>
                            <Typography align={'center'} style={{fontSize: title}}>
                                Estimado usuario introduce tu nueva contraseña.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <form onSubmit={onSubmit}>
                                <Grid container justify={'center'} alignItems={'center'}>
                                    <Grid item xs={12} md={6} lg={3}>
                                        <TextInput onChange={onChange} name={'password'} type={'password'}
                                                   placeHolder={'Contraseña'}>
                                            {password}
                                        </TextInput>
                                    </Grid>
                                    <Grid item xs={12}
                                          style={{display: 'flex', justifyContent: 'center', marginTop: "1.5%"}}>
                                        <Button type={'submit'} style={{fontSize: text}} color={'primary'}
                                                variant={"contained"}>
                                            Aceptar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid></> : ""
                }
                {
                    loaded && status === 'NOTK' ? <>
                        <Grid item xs={12}>
                            <Typography style={{fontSize: title}} align={'center'}>
                                No estas autorizado
                            </Typography>
                        </Grid>
                    </> : ""
                }
                {
                    loaded && status === 'ACTIVATED' ? <>
                        <Grid item xs={12}>
                            <Typography style={{fontSize: title, display: 'flex', justifyContent: 'center'}}
                                        align={'center'}>
                                Cuenta activada, da clic <a href="https://www.inxel.mx/login">aqui</a>, selecciona el
                                campo de Soy distribuidor e inicia sesión.
                            </Typography>
                        </Grid>
                    </> : ""
                }
                {
                    loaded && status === 'N403' ? <>
                        <Grid item xs={12}>
                            <Typography style={{fontSize: title}} align={'center'}>
                                Token inválido o tiempo limite superado.
                            </Typography>
                        </Grid>
                    </> : ""
                }
                {
                    loaded && status === 'UNK' ? <>
                        <Grid item xs={12}>
                            <Typography style={{fontSize: title}} align={'center'}>
                                Error desconocido
                            </Typography>
                        </Grid>
                    </> : ""
                }
            </Grid>
        </Grid>

    </Grid>
}