import React, {useEffect} from "react";
import {Button, Grid} from "@material-ui/core";
import PayForm from "../../components/PayForm";
import socketIOClient from "socket.io-client";

const ENDPOINT = "http://localhost:3000";
export default function PaymentRequest() {
    // eslint-disable-next-line no-undef

    useEffect(() => {
        const socket = socketIOClient(ENDPOINT);
        socket.on("welcome", data => {
            //setResponse(data);
        });

    }, []);

    return <Grid container>
        <Grid item xs={12}>
            <PayForm/>
        </Grid>
    </Grid>
}