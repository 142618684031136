import React, {useEffect} from "react";
import {Grid} from "@material-ui/core";
import Personal from "./Personal";
import Identification from "./Identification";
import {makeStyles} from "@material-ui/styles";
import VentasProductos from "../VentaProductos";
import useResponsiveValues from "../../hooks/useResponsiveValues";

const useStyles = makeStyles({
    element: {
        marginTop: '2%'
    }
})
export default function CustomerDataRequest({data, setData, pre}) {
    const classes = useStyles();
    return <Grid container spacing={2}>
        <Grid item xs={12} className={classes.element}>
            <Personal dataSup={data} setDataSup={setData} pre={pre}/>
        </Grid>
        {
            !pre ? <Grid item xs={12} className={classes.element}>
                <Identification dataSup={data} setDataSup={setData}/>
            </Grid> : ""
        }

    </Grid>
}
