import {combineReducers, createStore} from "redux";
import LoginReducer from "./LoginReducer";
import UserDetailReducer from "./UserDetailReducer";
import Notifications from './NotificationReducer';
const rootReducer = combineReducers({
    login: LoginReducer,
    detail:UserDetailReducer,
    notifications:Notifications
});
/* eslint-disable no-underscore-dangle */
const myStore = process.env.NODE_ENV === 'development' ? createStore(
    rootReducer /* preloadedState, */
    , window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
) : createStore(
    rootReducer /* preloadedState, */
  // , window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
/* eslint-enable */
export default myStore;
