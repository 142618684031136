/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons

import TableList from 'views/TableList/TableList.js'

import NuevaVenta from './views/NuevaVenta'
import NuevaVentaPre from './views/NuevaVentaPre'
import Pruebas from './views/Pruebas'
import Recargas from './views/Recargas'
import Colaboradores from './views/Colaboradores'
import Migraciones from './views/Migraciones'
import Reportes from './views/Reportes'
import Assessment from 'views/Assessment'

const _dashboardRoutes = [
    {
        path: '/postpago-new',
        name: 'Venta postpago',
        //rtlName: "قائمة الجدول",
        icon: 'content_paste',
        component: NuevaVenta,
        layout: '/admin',
    },
    {
        path: '/prepago-news',
        name: 'Venta prepago',
        //rtlName: "قائمة الجدول",
        icon: 'content_paste',
        component: NuevaVentaPre,
        layout: '/admin',
    },
    {
        path: '/pruebas',
        name: 'Pruebas',
        //rtlName: "قائمة الجدول",
        icon: 'content_paste',
        component: Pruebas,
        layout: '/admin',
    },
    {
        path: '/recargas',
        name: 'Recargas',
        //rtlName: "قائمة الجدول",
        icon: 'content_paste',
        component: Recargas,
        layout: '/admin',
    },
    {
        path: '/colaborator',
        name: 'Colaboradores',
        //rtlName: "قائمة الجدول",
        icon: 'content_paste',
        component: Colaboradores,
        layout: '/admin',
    },
    {
        path: '/assessment',
        name: 'Asistencia Total',
        icon: 'content_paste',
        component: Assessment,
        layout: '/admin',
    },
]

// allowed for Casa Mazatlan's Collaborators
const allowedForCMColls = ['recargas', 'prepago-news', 'assessment']
var dashboardRoutes = []
if (
    window.localStorage.getItem('accessLevel') === '0' &&
    window.localStorage.getItem('distributorId') === '213'
)
    dashboardRoutes = _dashboardRoutes.filter(route =>
        allowedForCMColls.find(r => '/' + r === route.path),
    )
else dashboardRoutes = _dashboardRoutes

export default dashboardRoutes
