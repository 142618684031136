import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Hidden from '@material-ui/core/Hidden'
// @material-ui/icons
import Menu from '@material-ui/icons/Menu'
// core components
import AdminNavbarLinks from './AdminNavbarLinks.js'
import RTLNavbarLinks from './RTLNavbarLinks.js'
import Button from 'components/CustomButtons/Button.js'

import styles from 'assets/jss/material-dashboard-react/components/headerStyle.js'
import {XEL_COLORS} from 'constants/index.js'

const useStyles = makeStyles(styles)

export default function Header(props) {
    const classes = useStyles()
    function makeBrand() {
        var name
        props.routes.map(prop => {
            if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
                name = props.rtlActive ? prop.rtlName : prop.name
            }
            return null
        })
        return name
    }
    const { color } = props
    const appBarClasses = classNames({
        [' ' + classes[color]]: color,
    })
    return (
        <AppBar className={classes.appBar + appBarClasses}>
            <Toolbar className={classes.container}>
                <div className={classes.flex}>
                    {/* Here we create navbar brand, based on route name */}
                </div>
                <Hidden smDown implementation="css">
                    {props.rtlActive ? (
                        <RTLNavbarLinks />
                    ) : (
                        <AdminNavbarLinks />
                    )}
                </Hidden>
                <Hidden mdUp implementation="css">
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={props.handleDrawerToggle}
                    >
                        <Menu />
                    </IconButton>
                </Hidden>
            </Toolbar>
            {makeBrand() && (
                <div style={{ margin: '10px 20px' }}>
                    <div
                        style={{
                            backgroundImage:
                                'linear-gradient(90deg, rgba(255,138,33,1) 45%, rgba(255,138,33,0) 100%)',
                            borderRadius: 20,
                            padding: "30px 20px",
                            fontSize: 30,
                            fontWeight: 700,
			    color: XEL_COLORS.primary
                        }}
                    >
                        {makeBrand()}
                    </div>
                </div>
            )}
        </AppBar>
    )
}

Header.propTypes = {
    color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
    rtlActive: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    routes: PropTypes.arrayOf(PropTypes.object),
}
