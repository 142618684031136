import React, {useEffect} from "react";
import {CircularProgress, makeStyles, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";


const useStyles = makeStyles({
    root: {
        width: '100vw',
        height: '100vh',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
    },
    text: {
        padding: '2%'
    }
});

export default function NotLogged() {
    const styles = useStyles();

    useEffect(() => {
    }, [])

    return <Grid className={styles.root}>
        <CircularProgress/>
        <Typography className={styles.text} align={'center'}>
            No estas autorizado, redirigiendo a inXel
        </Typography>
    </Grid>
}
