import React, {useState} from "react";
import {Grid, TextField} from "@material-ui/core";
import Escanner from "../../components/Escanner";
import EscannerV2 from '../../components/EscannerV2';

const Pruebas = () => {
    const [open, setOpen] = useState(true);
    const [icc, setIcc] = useState('');
    return <Grid container>
        <TextField value={icc} name={'ICC'}/>
        <Escanner setIcc={setIcc} open={open} setOpen={setOpen}/>
    </Grid>
}

export default Pruebas;