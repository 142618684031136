const LoginReducer = (
    state = { logged: false, token: null, accessLevel: null },
    action,
) => {
    switch (action.type) {
        case 'SIGN_IN':
            return (state = {
                logged: true,
                token: action.payload.token,
                accessLevel: action.payload.accessLevel,
                distributorId: action.payload.distributorId,
            })
        case 'LOGOUT':
            return (state = {
                logged: false,
                token: null,
                accessLevel: null,
                distributorId: null,
            })
        default:
            return state
    }
}
export default LoginReducer
