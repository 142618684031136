import React, {useEffect, useState} from "react";
import {Axios, XEL_COLORS, XEL_FONT} from "../../../constants";
import {
    Button,
    Checkbox, CircularProgress,
    FormControlLabel,
    FormGroup,
    Grid, makeStyles,
    TableContainer, TableFooter,
    TextField,
    Typography, withStyles
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const Movimientos = () => {
    const options = [
        {
            name: "Fecha",
            value: 1010
        },
        {
            name: "Número",
            value: 2020
        }
    ]
    const classes = useStyles();
    const [value, setValue] = useState(options[0].value);
    const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));
    const [rows, setRows] = useState([]);
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    let date = new Date();
    let startDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    const [dates, setDates] = useState({
        start: "",
        end: ""
    });
    const [total, setTotal] = useState(0);
    const [resetRequired, setResetRequired] = useState(false);
    const [startIn, setStarin] = useState(0);
    const [importe, setImporte] = useState(30);
    const [loading, setLoading] = useState(false);
    const [msisdn, setMsisdn] = useState("");

    const [filtros, setFiltros] = useState({
        fechas: true,
        importe: false,
        numero: false
    })
    let axios = Axios();

    const [data, setData] = useState({
        startedWith: 0,
        transfers: [],
        pays: [],
        total_pays: 0,
        total_recharges: 0,
        xelcash: 0
    });

    useEffect(() => {
        let defaultDate = new Date();
        let date = defaultDate.getDate();
        if (date < 10)
            date = `0${date}`;
        let month = defaultDate.getMonth() + 1;
        if (month < 10)
            month = `0${month}`;
        let defDate = `${defaultDate.getFullYear()}-${month}-${date}`;
        setDates({
            start: defDate,
            end: defDate
        })
        console.log({
            start: defDate,
            end: defDate
        })
    }, [])

    const handleDates = (e) => {
        let fecs = dates;
        let id = e.target.id;
        console.log("asfasf", e.target.value)
        switch (id) {
            case "date-start":
                fecs.start = e.target.value;
                break;
            case "date-end":
                fecs.end = e.target.value;
                break;
            default:
                return;

        }
        setResetRequired(true);
        setStarin(0);
        setDates(fecs);
    }
    const validFilters = () => {
        let filter = {
            dates
        };
        if (filtros.importe) {
            if (importe < 30) {
                window.alert("El importe minimo es de 30");
                return;
            }
            filter = {
                ...filter,
                importe
            }
        }
        if (filtros.numero) {
            if (msisdn.toString().split("").length !== 10) {
                window.alert("DN no válido");
                return;
            }
            filter = {
                ...filter,
                msisdn
            }
        }
        return filter;
    }
    const search = () => new Promise((resolve, reject) => {
        let filter = validFilters();
        axios.get(`/distributor/reports/movimientos?start=${dates.start}&end=${dates.end}`).then(res => {
            let data = res.data;
            setLoading(false);
            if (data.error) {
                window.alert(data.error_description);
                return;
            }
            setData(res.data);
            resolve(res);
        }).catch(err => {

            reject(err);
        })
    })
    const handleReport = () => {
        let filter = validFilters();

        let email = window.prompt("Introduce el correo al que deseas enviar el reporte");

        if (!email || email === "") {
            window.alert("Introduce un correo valido");
            return;
        }
        setLoading(true);
        axios.post("distributor/reports/report", {
            start: startIn,
            filter,
            email
        }).then(res => {
            console.log(res.data);
            if (res.data.error) {
                window.alert(res.data.error_description);
                setLoading(false);
                return;
            }
            window.alert("Tu reporte se esta generando, se enviara en cuanto se complete");
            setLoading(false);
        }).catch(err => {
            window.alert("Ocurrio un error al procesar tu petición verifica si tu correo o los parametros, puedes intentarlo nuevamente, si el problema persiste intenta reiniciar sesión");
            setLoading(false);
        })

    }

    const handleSearch = () => {
        setLoading(true);
        search().then(res => {
            let response = res.data;
            console.log(response);
            /*if (response.error) {
                window.alert(response.error_description);
                setLoading(false);
                return;
            }
            setStarin(response.next);
            setTotal(response.total);
            setRows(response.elements);
            setLoading(false);*/
            setLoading(false);
        }).catch(err => {
            console.error(err);
        })
    }
    const handleAttach = () => {
        setLoading(true);
        search().then(res => {
            let response = res.data;

            if (response.error) {
                window.alert(response.error_description);
                setLoading(false);
                return;
            }
            setStarin(response.next);
            setTotal(response.total);
            let befRows = rows;
            console.log(befRows)
            for (let i = 0; i < response.elements.length; i++) {
                befRows.push(response.elements[i]);
            }
            setRows(befRows);
            setLoading(false);
        }).catch(err => {
            console.error(err);
            setLoading(false);
        })
    }
    // const [options, setOptions] = useState(["Fecha", "Número"]);
    return <Grid container>
        {
            //<Grid item xs={12}>
            //             <Grid container direction={"column"}>
            //                 <Grid item xs={12}>
            //                     <Typography variant={"body2"}>
            //                         Filtros
            //                     </Typography>
            //                 </Grid>
            //                 <Grid item xs={12}>
            //                     <FormGroup row>
            //                         <FormControlLabel
            //                             control={
            //                                 <Checkbox
            //                                     checked={true}
            //                                     // checked={state.checkedB}
            //                                     //   onChange={handleChange}
            //                                     name="checkedB"
            //                                     color="primary"
            //                                 />
            //                             }
            //                             disabled={true}
            //                             label="Fecha"
            //                         />
            //                         <FormControlLabel
            //                             control={
            //                                 <Checkbox
            //                                     checked={filtros.importe}
            //                                     onChange={() => {
            //                                         setFiltros({
            //                                             ...filtros,
            //                                             importe: !filtros.importe
            //                                         })
            //                                     }}
            //                                     name="importeCheck"
            //                                     color="primary"
            //                                 />
            //                             }
            //                             label="Importe"
            //                         />
            //                         <FormControlLabel
            //                             control={
            //                                 <Checkbox
            //                                     checked={filtros.numero}
            //                                     onChange={() => {
            //                                         setFiltros({
            //                                             ...filtros,
            //                                             numero: !filtros.numero
            //                                         })
            //                                     }}
            //                                     name="DNCheck"
            //                                     color="primary"
            //                                 />
            //                             }
            //                             label="DN"
            //                         />
            //                     </FormGroup>
            //                 </Grid>
            //             </Grid>
            //         </Grid>
        }
        <Grid xs={12} className={"options-report"}>
            <Grid container justify={"center"}>
                <Grid item xs={12} md={4} lg={4} style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    paddingLeft: "1%"
                }}>
                    <TextField

                        id={'date-start'}
                        label="Desde"
                        type="date"

                        //  className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}

                        onChange={handleDates}
                    />
                    <TextField
                        id={'date-end'}
                        label="Hasta"
                        type="date"

                        //  className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}

                        onChange={handleDates}
                    />

                </Grid>
                {
                    filtros.importe && <Grid item xs={10} md={3} lg={2}>
                        <TextField
                            id={'amount-tosearch'}
                            label="Importe"
                            type="number"
                            defaultValue={30}
                            //defaultValue={defaultDate}
                            //  className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                min: "30",
                                max: "500"
                            }}
                            onChange={e => setImporte(e.target.value)}
                        />
                    </Grid>
                }
                {
                    filtros.numero && <Grid item xs={10} md={3} lg={2}>
                        <TextField
                            id={'dn-filter'}
                            label="DN"
                            type="text"
                            //defaultValue={30}
                            //defaultValue={defaultDate}
                            //  className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                min: "30",
                                max: "500"
                            }}
                            onChange={e => {
                                const val = e.target.value;
                                if (isNaN(val)) {
                                    return;
                                }
                                if (val.toString().split('').length > 10) {
                                    return;
                                }
                                setMsisdn(val);
                            }
                            }
                            value={msisdn}
                        />
                    </Grid>
                }
                <Grid item xs={4} md={3}
                      style={{display: "flex", justifyContent: "space-around", alignItems: "center"}}>
                    <Button variant={"contained"} onClick={handleSearch}>
                        <Typography variant={"subtitle2"}>
                            Buscar
                        </Typography>
                    </Button>
                    <Button variant={"contained"} onClick={handleReport}>
                        <Typography variant={"subtitle2"}>
                            Generar reporte
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Grid>

        <Grid item xs={12}>
            <Grid container>
                {
                    loading && <Grid item xs={12}>
                        <CircularProgress color={"primary"} size={40}/>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography style={{fontWeight: XEL_FONT.bold_weight}}>
                                Iniciaste el dia con XELCASH: ${data.startedWith}
                            </Typography>
                        </Grid>

                        {
                            data.pays.length === 0 ? <Grid item xs={12}>
                                <Typography>
                                    No hay compras
                                </Typography>
                            </Grid> : <>
                                <Grid item xs={12}>
                                    <Typography color={"primary"} style={{fontWeight: XEL_FONT.bold_weight}}>
                                        Compras XELCASH
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid xs={6} md={3}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Typography style={{fontWeight: XEL_FONT.bold_weight}}
                                                                color={"primary"}>
                                                        Fecha de compra
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid xs={6} md={3}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Typography style={{fontWeight: XEL_FONT.bold_weight}}
                                                                color={"primary"}>
                                                        Monto
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {
                                            data.pays.map((pay, index) => {
                                                return <Grid item xs={12} key={index}>
                                                    <Grid container>
                                                        <Grid xs={6} md={3}>
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    <Typography style={{color: "#000000"}}>
                                                                        {pay.date}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid xs={6} md={3}>
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    <Typography style={{color: "#000000"}}>
                                                                        ${pay.amount}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            })
                                        }
                                        <Grid xs={6} md={3} style={{marginTop: 4}}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Typography style={{fontWeight: XEL_FONT.bold_weight}}
                                                                color={"primary"}>
                                                        Total
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid xs={6} md={3} style={{marginTop: 4}}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        color={"primary"}>
                                                        ${data.total_pays}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        }

                        {
                            data.transfers.length > 0 && <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: XEL_FONT.bold_weight}}
                                                    color={"primary"}>
                                            Transferencias
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={3}>
                                                <Typography color={"primary"}>
                                                    Fecha
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography color={"primary"}>
                                                    Monto
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography color={"primary"}>
                                                    Colaborador
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {
                                        data.transfers.map((transfer, index) => {
                                            return <Grid item xs={12} key={index}>
                                                <Grid container>
                                                    <Grid item xs={3}>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <Typography style={{color: "#000000"}}>
                                                                    {transfer.date}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <Typography style={{color: "#000000"}}>
                                                                    ${transfer.amount}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <Typography style={{color: "#000000"}}>
                                                                    {transfer.username}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        })
                                    }
                                    <Grid item xs={12} style={{marginTop: 4}}>
                                        <Grid container>
                                            <Grid item xs={3}>
                                                <Typography color={"primary"}>
                                                    Total en transferencias
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography color={"primary"}>
                                                    ${data.total_transfers}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }

                        <Grid item xs={12}>
                            <Typography>
                                Total en recargas ${data.total_recharges}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                Gasto total ${data.total}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                Saldo final ${data.xelcash}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}

export default Movimientos;
