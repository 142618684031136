import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import {Grid} from "@material-ui/core";
import useResponsiveValues from "../../hooks/useResponsiveValues";

export default function TextInput({placeHolder, name, error, onChange, helperText, type, children}) {
    const text = useResponsiveValues(13, 14, 16, 18)
    return <FormControl color={'primary'} fullWidth onChange={onChange}>
        <InputLabel style={{fontSize: text}} htmlFor={`txt-ipt-${name}`}>{placeHolder}</InputLabel>
        <Input style={{fontSize: text,textTransform:'capitalize'}} type={type ? type : ""} color={'primary'} id={`txt-ipt-${name}`} name={name}
               value={children}
               aria-describedby={`helper-text-${name}`}/>
        {
            helperText ? <FormHelperText id={`helper-text-${name}`} error={error}>{helperText}</FormHelperText> : ""
        }
    </FormControl>
}