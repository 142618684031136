/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import inXeLogo from '../../assets/logos/inxel/white-logo.png'
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks.js";

import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";
import {useSelector} from "react-redux";

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
    const classes = useStyles();

    const profileDetails = useSelector(state => state.detail)

    // verifies if routeName is the one active (in browser input)
    function activeRoute(routeName) {
        return window.location.href.indexOf(routeName) > -1 ? true : false;
    }

    const session = useSelector(state => state.detail);

    const {color, logo, image, logoText, routes} = props;
    var links = (
        <List className={classes.list}>
            {routes.map((prop, key) => {
                var activePro = " ";
                var listItemClasses;

                listItemClasses = classNames({
                    [" " + classes[color]]: activeRoute(prop.layout + prop.path)
                });

                const whiteFontClasses = classNames({
                    [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
                });

                if (prop.path === '/pruebas') {
                    if (session.permisos.pruebas)
                        return (
                            <NavLink
                                to={prop.layout + prop.path}
                                className={activePro + classes.item}
                                activeClassName="active"
                                key={key}
                            >
                                <ListItem button className={classes.itemLink + listItemClasses}>
                                    {typeof prop.icon === "string" ? (
                                        <Icon
                                            className={classNames(classes.itemIcon, whiteFontClasses, {
                                                [classes.itemIconRTL]: props.rtlActive
                                            })}
                                        >
                                            {prop.icon}
                                        </Icon>
                                    ) : (
                                        <prop.icon
                                            className={classNames(classes.itemIcon, whiteFontClasses, {
                                                [classes.itemIconRTL]: props.rtlActive
                                            })}
                                        />
                                    )}
                                    <ListItemText
                                        primary={props.rtlActive ? prop.rtlName : prop.name}
                                        className={classNames(classes.itemText, whiteFontClasses, {
                                            [classes.itemTextRTL]: props.rtlActive
                                        })}
                                        disableTypography={true}
                                    />
                                </ListItem>
                            </NavLink>
                        );
                    else
                        return null;
                }
                if (prop.path === '/ventas' || prop.path === '/colaborator') {
                    if (session.permisos.distributor)
                        return (
                            <NavLink
                                to={prop.layout + prop.path}
                                className={activePro + classes.item}
                                activeClassName="active"
                                key={key}
                            >
                                <ListItem button className={classes.itemLink + listItemClasses}>
                                    {typeof prop.icon === "string" ? (
                                        <Icon
                                            className={classNames(classes.itemIcon, whiteFontClasses, {
                                                [classes.itemIconRTL]: props.rtlActive
                                            })}
                                        >
                                            {prop.icon}
                                        </Icon>
                                    ) : (
                                        <prop.icon
                                            className={classNames(classes.itemIcon, whiteFontClasses, {
                                                [classes.itemIconRTL]: props.rtlActive
                                            })}
                                        />
                                    )}
                                    <ListItemText
                                        primary={props.rtlActive ? prop.rtlName : prop.name}
                                        className={classNames(classes.itemText, whiteFontClasses, {
                                            [classes.itemTextRTL]: props.rtlActive
                                        })}
                                        disableTypography={true}
                                    />
                                </ListItem>
                            </NavLink>
                        );
                    else
                        return null;
                }

                if (prop.path === '/postpago-new') {
                    if (session.permisos.postpago)
                        return (
                            <NavLink
                                to={prop.layout + prop.path}
                                className={activePro + classes.item}
                                activeClassName="active"
                                key={key}
                            >
                                <ListItem state button className={classes.itemLink + listItemClasses}>
                                    {typeof prop.icon === "string" ? (
                                        <Icon
                                            className={classNames(classes.itemIcon, whiteFontClasses, {
                                                [classes.itemIconRTL]: props.rtlActive
                                            })}
                                        >
                                            {prop.icon}
                                        </Icon>
                                    ) : (
                                        <prop.icon
                                            className={classNames(classes.itemIcon, whiteFontClasses, {
                                                [classes.itemIconRTL]: props.rtlActive
                                            })}
                                        />
                                    )}
                                    <ListItemText
                                        primary={props.rtlActive ? prop.rtlName : prop.name}
                                        className={classNames(classes.itemText, whiteFontClasses, {
                                            [classes.itemTextRTL]: props.rtlActive
                                        })}
                                        disableTypography={true}
                                    />
                                </ListItem>
                            </NavLink>
                        );
                    else
                        return null;
                }
                if (prop.path === '/prepago-news') {
                    if (session.permisos.prepago)
                        return (
                            <NavLink
                                to={prop.layout + prop.path}
                                className={activePro + classes.item}
                                activeClassName="active"
                                key={key}
                            >
                                <ListItem button className={classes.itemLink + listItemClasses}>
                                    {typeof prop.icon === "string" ? (
                                        <Icon
                                            className={classNames(classes.itemIcon, whiteFontClasses, {
                                                [classes.itemIconRTL]: props.rtlActive
                                            })}
                                        >
                                            {prop.icon}
                                        </Icon>
                                    ) : (
                                        <prop.icon
                                            className={classNames(classes.itemIcon, whiteFontClasses, {
                                                [classes.itemIconRTL]: props.rtlActive
                                            })}
                                        />
                                    )}
                                    <ListItemText
                                        primary={props.rtlActive ? prop.rtlName : prop.name}
                                        className={classNames(classes.itemText, whiteFontClasses, {
                                            [classes.itemTextRTL]: props.rtlActive
                                        })}
                                        disableTypography={true}
                                    />
                                </ListItem>
                            </NavLink>
                        );
                    else
                        return null;
                }
                return (
                    <NavLink
                        to={prop.layout + prop.path}
                        className={activePro + classes.item}
                        activeClassName="active"
                        key={key}
                    >
                        <ListItem button className={classes.itemLink + listItemClasses}>
                            {typeof prop.icon === "string" ? (
                                <Icon
                                    className={classNames(classes.itemIcon, whiteFontClasses, {
                                        [classes.itemIconRTL]: props.rtlActive
                                    })}
                                >
                                    {prop.icon}
                                </Icon>
                            ) : (
                                <prop.icon
                                    className={classNames(classes.itemIcon, whiteFontClasses, {
                                        [classes.itemIconRTL]: props.rtlActive
                                    })}
                                />
                            )}
                            <ListItemText
                                primary={props.rtlActive ? prop.rtlName : prop.name}
                                className={classNames(classes.itemText, whiteFontClasses, {
                                    [classes.itemTextRTL]: props.rtlActive
                                })}
                                disableTypography={true}
                            />
                        </ListItem>
                    </NavLink>
                );
            })}
        </List>
    );
    var brand = (
        <div className={classes.logo}>
            <img src={inXeLogo} alt="logo" className={classes.img}/>
            <strong style={{color: '#fefefe', textAlign: 'center'}}>
                {
                    profileDetails.name
                }
            </strong>
        </div>
    );
    return (
        <div>
            <Hidden mdUp implementation="css">
                <Drawer
                    variant="temporary"
                    anchor={props.rtlActive ? "left" : "right"}
                    open={props.open}
                    classes={{
                        paper: classNames(classes.drawerPaper, {
                            [classes.drawerPaperRTL]: props.rtlActive
                        })
                    }}
                    onClose={props.handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true // Better open performance on mobile.
                    }}
                >
                    {brand}
                    <div className={classes.sidebarWrapper}>
                        {props.rtlActive ? <RTLNavbarLinks/> : <AdminNavbarLinks/>}
                        {links}
                    </div>
                    {image !== undefined ? (
                        <div
                            className={classes.background}
			    style={{ backgroundImage: "url(" + image + ")" }}
                        />
                    ) : null}
                </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
                <Drawer
                    anchor={props.rtlActive ? "right" : "left"}
                    variant="permanent"
                    open
                    classes={{
                        paper: classNames(classes.drawerPaper, {
                            [classes.drawerPaperRTL]: props.rtlActive
                        })
                    }}
                >
                    {brand}
                    <div className={classes.sidebarWrapper}>{links}</div>
                    {image !== undefined ? (
                        <div
                            className={classes.background}
			style={{ backgroundImage: "url(" + image + ")" }}
                        />
                    ) : null}
                </Drawer>
            </Hidden>
        </div>
    );
}

Sidebar.propTypes = {
    rtlActive: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
    logo: PropTypes.string,
    image: PropTypes.string,
    logoText: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object),
    open: PropTypes.bool
};
