import React, { useEffect, useState } from 'react'
import {
    Checkbox,
    FormGroup,
    FormHelperText,
    Grid,
    makeStyles,
    Typography,
} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import TextInput from '../TextInput'
import Axios from 'axios'
import NumberFormat from 'react-number-format'
import getPlanes from '../../services/getPlanes'
import { XEL_COLORS, XEL_FONT } from '../../constants'
import OpenPayForm from '../OpenpayForm'
import useResponsiveValues from '../../hooks/useResponsiveValues'
import Button from '@material-ui/core/Button'
import Escanner from '../Escanner'
import { useSelector } from 'react-redux'

const useStyles = makeStyles({
    productContainer: {
        borderRadius: 10,
        backgroundColor: '#FEFEFE',
        padding: '1%',
    },
    text_title: {
        color: '#000000',
    },
    section: {
        marginTop: '2%',
    },
})

export default function VentasProductos({ data, setData, pre }) {
    const styles = useStyles()

    const [tipoVenta, setTipoVenta] = useState('NEW')
    const [service, setService] = useState('MOVIL')
    const session = useSelector(state => state.detail)
    const [enableExtras, setEnableExtras] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState(null)

    useEffect(() => {
        setData({
            ...data,
            tipoVenta: 'NEW',
        })
    }, [])
    useEffect(() => {
        if (selectedProduct)
            if (parseInt(selectedProduct) === 39846) {
                if (session.permisos.extras) setEnableExtras(true)
            } else {
                setEnableExtras(false)
                setData({
                    ...data,
                    services: [],
                })
            }
    }, [selectedProduct])

    useEffect(() => {
        setData({
            ...data,
            tipoVenta,
            nir: tipoVenta !== 'NEW' ? '' : data.nir,
        })
    }, [tipoVenta])

    const addExtraService = Id => {
        let services = data.services
        if (!services) services = []
        services.push(Id)
        setData({
            ...data,
            services,
        })
    }
    const delExtraService = Id => {
        let services = data.services
        if (!services) services = []
        let nServices = []
        for (let i = 0; i < services.length; i++) {
            if (Id !== services[i]) nServices.push(services[i])
        }
        services.push(Id)
        setData({
            ...data,
            services: nServices,
        })
    }
    const handleChange = e => {
        setTipoVenta(e.target.value)
    }
    const handleChangeService = e => {
        setService(e.target.value)
    }
    const handleNir = e => {
        setData({
            ...data,
            nir: e.target.value,
        })
    }

    return (
        <Grid
            container
            justify={'center'}
            spacing={2}
            className={styles.productContainer}
        >
            <Grid item xs={12}>
                <Grid container justify={'center'}>
                    {service === 'MOVIL' && (
                        <Grid
                            item
                            xs={10}
                            md={5}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <FormControl
                                component="fieldset"
                                onChange={handleChange}
                            >
                                <FormLabel
                                    component="legend"
                                    className={styles.text_title}
                                >
                                    Selecciona tipo de venta
                                </FormLabel>
                                <RadioGroup
                                    style={{ justifyContent: 'center' }}
                                    row
                                    aria-label="position"
                                    name="position"
                                    defaultValue="top"
                                >
                                    <FormControlLabel
                                        className={styles.text_title}
                                        value="NEW"
                                        control={<Radio color="primary" />}
                                        label="Línea nueva"
                                        labelPlacement="top"
                                        checked={tipoVenta === 'NEW'}
                                    />
                                    <FormControlLabel
                                        className={styles.text_title}
                                        value="PORTA"
                                        control={<Radio color="primary" />}
                                        label="Portabilidad"
                                        labelPlacement="top"
                                        checked={tipoVenta === 'PORTA'}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    )}
                    {service === 'HBB' && (
                        <Grid xs={12} md={4}>
			    <InputLabel>Coordenadas</InputLabel>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <div style={{ margin: 5 }}>
                                    <TextInput
                                        placeHolder={'Latitud'}
                                        onChange={e =>
                                            setData({
                                                ...data,
                                                coords: {
                                                    ...data.coords,
                                                    lat: e.target.value,
                                                },
                                            })
                                        }
                                    >
                                        {data.coords.lat}
                                    </TextInput>
                                </div>
                                <div style={{ margin: 5 }}>
                                    <TextInput
                                        placeHolder={'Longitud'}
                                        onChange={e =>
                                            setData({
                                                ...data,
                                                coords: {
                                                    ...data.coords,
                                                    lon: e.target.value,
                                                },
                                            })
                                        }
                                    >
                                        {data.coords.lon}
                                    </TextInput>
                                </div>
                            </div>
                        </Grid>
                    )}
                    <Grid
                        item
                        xs={10}
                        md={5}
                        style={{ display: 'flex', justifyContent: 'center' }}
                    >
                        <FormControl
                            component="fieldset"
                            onChange={handleChangeService}
                        >
                            <FormLabel
                                component="legend"
                                className={styles.text_title}
                            >
                                Selecciona el tipo de servicio
                            </FormLabel>
                            <RadioGroup
                                style={{ justifyContent: 'center' }}
                                row
                                aria-label="position"
                                name="position"
                                defaultValue="top"
                            >
                                <FormControlLabel
                                    className={styles.text_title}
                                    value="MOVIL"
                                    control={<Radio color="primary" />}
                                    label="Movil"
                                    labelPlacement="top"
                                    checked={service === 'MOVIL'}
                                />
                                {((!pre && session.permisos.mifipostpago) ||
                                    (pre && session.permisos.mifiprepago)) && (
                                    <FormControlLabel
                                        className={styles.text_title}
                                        value="MIFI"
                                        control={<Radio color="primary" />}
                                        label="MiFi"
                                        labelPlacement="top"
                                        checked={service === 'MIFI'}
                                    />
                                )}
                                {
                                    // <FormControlLabel
                                    //     className={styles.text_title}
                                    //     value="HBB"
                                    //     control={<Radio color="primary" />}
                                    //     label="Internet Fijo"
                                    //     labelPlacement="top"
                                    //     checked={service === 'HBB'}
                                    // />
                                }
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            {service === 'MOVIL' && tipoVenta === 'NEW' && (
                <Grid item xs={12} md={4}>
                    <TextInput
                        helperText={''}
                        onChange={handleNir}
                        name={'nip'}
                        placeHolder={'NIR'}
                        type={'number'}
                    >
                        {data.nir ? data.nir : ''}
                    </TextInput>
                </Grid>
            )}
            {tipoVenta === 'PORTA' ? (
                <Grid item xs={12}>
                    <Porta data={data} setData={setData} />
                </Grid>
            ) : (
                ''
            )}
            <Grid item xs={12}>
                <Products
                    data={data}
                    setData={setData}
                    pre={pre}
                    service={service}
                    selectProduct={setSelectedProduct}
                />
            </Grid>
            {enableExtras && (
                <Extras
                    addExtraService={addExtraService}
                    delExtraService={delExtraService}
                />
            )}
        </Grid>
    )
}

export const Extras = ({ addExtraService, delExtraService }) => {
    const [extraServices, setExtraServices] = useState([])

    useEffect(() => {
        Axios.get('https://api.inxel.mx/catalog/products/extras')
            .then(res => {
                let response = res.data
                setExtraServices(response)
            })
            .catch(err => {})
    }, [])
    return (
        <Grid item xs={12}>
            <Grid
                container
                style={{
                    borderRadius: 10,
                    border: `1px solid ${XEL_COLORS.primary}`,
                    padding: '1%',
                }}
            >
                <Grid item xs={12}>
                    <Typography
                        variant={'body1'}
                        style={{ fontWeight: XEL_FONT.bold_weight }}
                    >
                        Servicios adicionales
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction={'column'}>
                        {extraServices.map((s, i) => (
                            <Grid key={i} item xs={6}>
                                <FormControlLabel
                                    classes={{
                                        label: {
                                            color: '#000000',
                                        },
                                    }}
                                    control={
                                        <Checkbox //checked={true}
                                            color={'primary'}
                                            onChange={e => {
                                                console.log(e.target.checked)
                                                if (e.target.checked)
                                                    addExtraService(s.id)
                                                else {
                                                    delExtraService(s.id)
                                                }
                                            }}
                                            name={s.id}
                                        />
                                    }
                                    label={`${s.name} + $${s.price} ${s.period}`}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

const Porta = ({ data, setData }) => {
    const styles = useStyles()
    const [selCompanie, setSelCompanie] = useState()
    const [companies, setCompanies] = useState([])
    const [tporta, setTporta] = useState('')
    useEffect(() => {
        getCompanies.then(res => {
            if (res.status === 'OK') {
                setCompanies(res.response.data)
                setSelCompanie(res.response.data[0]['ID'])
                console.log(res.response.data)
            } else {
                alert('No se cargaron algunos datos, verifica tu conectividad')
            }
        })
    }, [])

    useEffect(() => {
        setData({
            ...data,
            tporta,
        })
    }, [tporta])
    const getCompanies = new Promise((resolve, reject) => {
        Axios({
            method: 'GET',
            url: 'https://api.inxel.mx/catalog/origin/companies/',
        })
            .then(res => {
                resolve({
                    status: 'OK',
                    response: res,
                })
            })
            .catch(error => {
                resolve({
                    status: 'ERROR',
                    response: error,
                })
            })
    })
    const handleChangeCompanie = e => {
        setData({
            ...data,
            origin_companie: e.target.value,
        })
        setSelCompanie(e.target.value)
    }
    const handleNip = e => {
        setData({
            ...data,
            nip: e.target.value,
        })
    }

    return (
        <Grid
            container
            spacing={1}
            alignItems={'center'}
            justify={'center'}
            className={styles.section}
        >
            <Grid item xs={11} md={6}>
                <NumberFormat
                    name={'tporta'}
                    placeHolder={'Número a portar'}
                    error={false}
                    helperText={''}
                    //     onChange={handleChange}
                    format="(###) ###-####"
                    mask="_"
                    onValueChange={values => {
                        const { formattedValue, value } = values
                        setTporta(value)
                    }}
                    customInput={TextInput}

                    //  value={tcontacto}
                />
            </Grid>
            <Grid item xs={11} md={4}>
                <TextInput
                    helperText={'Marca 051 desde el número a portar'}
                    onChange={handleNip}
                    name={'nip'}
                    placeHolder={'NIP'}
                    type={'number'}
                >
                    {data.nip ? data.nip : ''}
                </TextInput>
            </Grid>

            <Grid item xs={11} md={6}>
                <Grid
                    container
                    spacing={1}
                    justify={'center'}
                    alignItems={'center'}
                >
                    <Grid item xs={12} md={4}>
                        <Typography align={'center'}>
                            Compañia de origen
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl
                            fullWidth
                            disabled={companies.length === 0}
                            //    className={classes.formControl}
                        >
                            <InputLabel id="label-select-companie">
                                Compañia
                            </InputLabel>
                            <Select
                                //labelId="select-label-identification"
                                id="select-companis"
                                value={selCompanie}
                                name={'identification'}
                                onChange={handleChangeCompanie}
                            >
                                {companies.map(companie => (
                                    <MenuItem
                                        key={companie.ID}
                                        value={companie.ID}
                                    >
                                        {companie.VALUE}
                                    </MenuItem>
                                ))}
                            </Select>
                            {companies.length === 0 ? (
                                <FormHelperText>Cargando...</FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const Products = ({ data, setData, pre, service, selectProduct }) => {
    const styles = useStyles()
    const [idSelected, setIdSelected] = useState(null)
    const [planes, setPlanes] = useState([])
    const [planSelectedDetail, setPlanSelectedDetail] = useState({})
    const [tipoV, setTipoV] = useState()
    const promoSize = useResponsiveValues(10, 11, 13)
    const session = useSelector(state => state.detail)
    useEffect(() => {
        setTipoV(data.tipoVenta)
    }, [data])
    useEffect(() => {
        setData({ ...data, service })
        getPlanes.then(res => {
            if (res.status === 'OK') {
                let arrp = []
                const d = res.response
                for (let i = 0; i < d.length; i++) {
                    console.log(d[i])
                    if (pre) {
                        if (
                            (d[i].modality === 'PRE' ||
                                d[i].modality === 'PREPOST') &&
                            service === 'MOVIL'
                        ) {
                            arrp.push(d[i])
                        }
                        if (
                            session.permisos.mifiprepago &&
                            service === 'MIFI'
                        ) {
                            if (d[i].modality === 'PREFI') {
                                arrp.push(d[i])
                            }
                        }
                        if (service === 'HBB' && d[i].modality === 'PREHOME')
                            arrp.push(d[i])
                    } else {
                        if (d[i].modality === 'POST' && service === 'MOVIL') {
                            arrp.push(d[i])
                        }
                        if (
                            session.permisos.mifipostpago &&
                            service === 'MIFI'
                        ) {
                            if (d[i].modality === 'POSTFI') {
                                arrp.push(d[i])
                            }
                        }
                        if (service === 'HBB' && d[i].modality === 'POSTHOME')
                            arrp.push(d[i])
                    }
                }
                setPlanes(arrp)
            } else console.log(res.error)
        })
    }, [service])

    useEffect(() => {
        planes.map(plan => {
            let ids = [
                {
                    id: plan.id_product,
                    months: 0,
                },
            ]
            if (plan.promo) {
                for (let i = 0; i < plan.promo.length; i++) {
                    ids.push({
                        id: plan.promo[i].id_product,
                        months: plan.promo[i].months,
                    })
                }
            }

            for (let i = 0; i < ids.length; i++) {
                if (parseInt(ids[i].id) === parseInt(idSelected)) {
                    setData({
                        ...data,
                        product: idSelected.toString(),
                        price: plan.price_original,
                        months: ids[i].months,
                    })
                    setPlanSelectedDetail(plan)
                }
            }
        })
    }, [idSelected])
    const handleChange = async e => {
        await setIdSelected(e.target.value)
        await selectProduct(e.target.value)
    }
    return (
        <Grid container spacing={2} className={styles.section}>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={10} md={6}></Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid
                    container
                    spacing={1}
                    justify={'center'}
                    alignItems={'center'}
                >
                    <Grid item xs={12} md={4}>
                        <Typography align={'center'}>
                            Selecciona {pre ? 'Recarga' : 'Plan'}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl
                            fullWidth
                            disabled={planes.length === 0}
                            //    className={classes.formControl}
                        >
                            <InputLabel id="select-plan-label">
                                {pre ? 'Recarga' : 'Plan'}
                            </InputLabel>
                            <Select
                                //  labelId="select-label-identification"
                                id="select-plan"
                                value={idSelected}
                                name={'plan-select'}
                                onChange={handleChange}
                            >
                                {planes.map((plan, index) => {
                                    let first = true
                                    let typ =
                                        tipoV === 'NEW' ? 'NUEVA' : 'PORTA'
                                    if (pre) {
                                        if (
                                            plan.modality === 'PRE' ||
                                            plan.modality === 'PREFI' ||
                                            plan.modality === 'PREPOST' ||
                                            plan.modality === 'PREHOME'
                                        ) {
                                            if (
                                                plan.promo &&
                                                plan.promo.length > 0
                                            ) {
                                                for (
                                                    let i = 0;
                                                    i < plan.promo.length;
                                                    i++
                                                ) {
                                                    let pl = plan.promo[i]
                                                    if (pl.for === typ) {
                                                        return (
                                                            <MenuItem
                                                                key={
                                                                    pl.id_product
                                                                }
                                                                value={`${pl.id_product}`}
                                                            >
                                                                <Grid
                                                                    container
                                                                    spacing={2}
                                                                >
                                                                    <Grid item>
                                                                        {
                                                                            plan.name
                                                                        }
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <NumberFormat
                                                                            thousandSeparator={
                                                                                true
                                                                            }
                                                                            prefix={
                                                                                '$'
                                                                            }
                                                                            style={{
                                                                                fontSize: promoSize,
                                                                                textDecoration:
                                                                                    'line-through',
                                                                            }}
                                                                            displayType={
                                                                                'text'
                                                                            }
                                                                            value={
                                                                                plan.price_original
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <NumberFormat
                                                                            thousandSeparator={
                                                                                true
                                                                            }
                                                                            prefix={
                                                                                '$'
                                                                            }
                                                                            displayType={
                                                                                'text'
                                                                            }
                                                                            value={
                                                                                pl.price
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </MenuItem>
                                                        )
                                                    }
                                                }
                                            }

                                            return (
                                                <MenuItem
                                                    key={plan.id_product}
                                                    value={`${plan.id_product}`}
                                                >
                                                    <Grid container spacing={2}>
                                                        <Grid item>
                                                            {plan.name}
                                                        </Grid>
                                                        <Grid item>
                                                            <NumberFormat
                                                                thousandSeparator={
                                                                    true
                                                                }
                                                                prefix={'$'}
                                                                displayType={
                                                                    'text'
                                                                }
                                                                value={
                                                                    plan.price
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </MenuItem>
                                            )
                                        }
                                    } else {
                                        if (
                                            plan.modality === 'POST' ||
                                            plan.modality === 'POSTFI' ||
                                            plan.modality === 'POSTHOME'
                                        ) {
                                            if (
                                                plan.promo &&
                                                plan.promo.length > 0
                                            ) {
                                                for (
                                                    let i = 0;
                                                    i < plan.promo.length;
                                                    i++
                                                ) {
                                                    let pl = plan.promo[i]
                                                    if (pl.for === typ) {
                                                        return (
                                                            <MenuItem
                                                                key={
                                                                    pl.id_product
                                                                }
                                                                value={`${pl.id_product}`}
                                                            >
                                                                <Grid
                                                                    container
                                                                    spacing={2}
                                                                >
                                                                    <Grid item>
                                                                        {
                                                                            plan.name
                                                                        }
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <NumberFormat
                                                                            thousandSeparator={
                                                                                true
                                                                            }
                                                                            prefix={
                                                                                '$'
                                                                            }
                                                                            style={{
                                                                                fontSize: promoSize,
                                                                                textDecoration:
                                                                                    'line-through',
                                                                            }}
                                                                            displayType={
                                                                                'text'
                                                                            }
                                                                            value={
                                                                                plan.price_original
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <NumberFormat
                                                                            thousandSeparator={
                                                                                true
                                                                            }
                                                                            prefix={
                                                                                '$'
                                                                            }
                                                                            displayType={
                                                                                'text'
                                                                            }
                                                                            value={
                                                                                pl.price
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </MenuItem>
                                                        )
                                                    }
                                                }
                                            }

                                            return (
                                                <MenuItem
                                                    key={plan.id_product}
                                                    value={`${plan.id_product}`}
                                                >
                                                    <Grid container spacing={2}>
                                                        <Grid item>
                                                            {plan.name}
                                                        </Grid>
                                                        <Grid item>
                                                            <NumberFormat
                                                                thousandSeparator={
                                                                    true
                                                                }
                                                                prefix={'$'}
                                                                displayType={
                                                                    'text'
                                                                }
                                                                value={
                                                                    plan.price
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </MenuItem>
                                            )
                                        }
                                    }
                                })}
                            </Select>
                            {planes.length === 0 ? (
                                <FormHelperText>
                                    Cargando planes...
                                </FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            {
                //{
                //             idSelected ? <Grid item xs={12}>
                //                 <Grid container justify={'center'} spacing={2}>
                //                     <Grid item xs={12}>
                //                         <Grid container justify={'center'}>
                //                             <Grid item xs={6} md={3}>
                //                                 <Typography align={'center'} color={'primary'}
                //                                             style={{fontWeight: XEL_FONT.bold_weight}}>
                //                                     GB:
                //                                 </Typography>
                //                             </Grid>
                //                             <Grid item xs={6} md={3}>
                //                                 <Typography
                //                                     align={'center'}>{parseInt(planSelectedDetail.local_gb) + parseInt(planSelectedDetail.roaming_gb)}</Typography>
                //                             </Grid>
                //                         </Grid>
                //                     </Grid>
                //
                //                     <Grid item xs={12}>
                //                         <Grid container justify={'center'}>
                //                             <Grid item xs={6} md={3}>
                //                                 <Typography align={'center'} color={'primary'}
                //                                             style={{fontWeight: XEL_FONT.bold_weight}}>
                //                                     Minutos:
                //                                 </Typography>
                //                             </Grid>
                //                             <Grid item xs={6} md={3}>
                //                                 <Typography
                //                                     align={'center'}>{parseInt(planSelectedDetail.local_minutes)}</Typography>
                //                             </Grid>
                //                         </Grid>
                //                     </Grid>
                //
                //                     <Grid item xs={12}>
                //                         <Grid container justify={'center'}>
                //                             <Grid item xs={6} md={3}>
                //                                 <Typography align={'center'} color={'primary'}
                //                                             style={{fontWeight: XEL_FONT.bold_weight}}>
                //                                     Mensajes:
                //                                 </Typography>
                //                             </Grid>
                //                             <Grid item xs={6} md={3}>
                //                                 <Typography
                //                                     align={'center'}>{parseInt(planSelectedDetail.local_sms)}</Typography>
                //                             </Grid>
                //                         </Grid>
                //                     </Grid>
                //
                //                     <Grid item xs={12}>
                //                         <Grid container justify={'center'}>
                //                             <Grid item xs={6} md={3}>
                //                                 <Typography align={'center'} color={'primary'}
                //                                             style={{fontWeight: XEL_FONT.bold_weight}}>
                //                                     Hotspot:
                //                                 </Typography>
                //                             </Grid>
                //                             <Grid item xs={6} md={3}>
                //                                 <Typography
                //                                     align={'center'}>
                //                                     {
                //                                         parseInt(planSelectedDetail.hotspot) === 1 ? 'Si' : 'No'
                //                                     }
                //                                 </Typography>
                //                             </Grid>
                //                         </Grid>
                //                     </Grid>
                //                     {
                //                         /*  {
                //                        0 === 1 ? <Grid item xs={12}>
                //                             <Grid container justify={'center'}>
                //                                 <Grid item xs={10}>
                //                                     <Typography align={'center'}
                //                                         //color={'primary'}
                //                                         //            style={{fontWeight: XEL_FONT.bold_weight}}
                //                                     >
                //                                         Cuenta con un Throttling de
                //                                         <span color={'primary'}
                //                                               style={{
                //                                                   fontWeight: XEL_FONT.bold_weight,
                //                                                   color: XEL_COLORS.primary
                //                                               }}> {planSelectedDetail.throttling_gb}GB
                //                                     </span> a
                //                                         velocidad
                //                                         de <span
                //                                         style={{
                //                                             fontWeight: XEL_FONT.bold_weight,
                //                                             color: XEL_COLORS.primary
                //                                         }}> {planSelectedDetail.throttling_speed}Kbps
                //                                     </span>
                //                                     </Typography>
                //                                 </Grid>
                //                             </Grid>
                //                         </Grid> : ''
                //                     }*/
                //                     }
                //                 </Grid>
                //             </Grid> : ''
                //         }
            }
        </Grid>
    )
}

export const DeviceData = ({ data, setData }) => {
    const styles = useStyles()
    const text = useResponsiveValues(11, 13, 16)
    const handleChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        })
    }
    return (
        <Grid container spacing={2} className={styles.productContainer}>
            <Grid item xs={12}>
                <Typography style={{ fontSize: text }}>
                    Inserta datos de dispositivo
                </Typography>
            </Grid>
            <Grid item xs={11} md={6}>
                <TextInput
                    helperText={'Marca *#06# para saberlo'}
                    onChange={handleChange}
                    type={'text'}
                    placeHolder={'IMEI'}
                    name={'imei'}
                >
                    {data.imei ? data.imei : ''}
                </TextInput>
            </Grid>
        </Grid>
    )
}

export const SIMData = ({ data, setData }) => {
    const styles = useStyles()
    const text = useResponsiveValues(11, 13, 16)
    const [open, setOpen] = useState(false)

    const [icc, setIcc] = useState(data.icc)
    const [dn, setDn] = useState('')
    const [isReplace, setIsReplace] = useState(false)
    useEffect(() => {
        setData({
            ...data,
            icc,
            replace: isReplace,
            dn,
        })
    }, [icc, isReplace, dn])
    const handleChange = ({ target: { value, name } }) => {
        if (name === 'icc') setIcc(value)
        else if (name === 'dn') {
            if (value.length > 10) return
            if (isNaN(value)) return
            setDn(value)
        }
    }
    return (
        <Grid container spacing={2} className={styles.productContainer}>
            <Grid item xs={12}>
            <FormGroup>
                <FormControlLabel
                    control={<Checkbox checked={isReplace} onChange={({target: {checked}}) => setIsReplace(checked)}
                                       color={"secondary"}/>}
                    label={<Typography color={"primary"}>Marque esta casilla si es un reemplazo</Typography>}/>
            </FormGroup>
	    </Grid>

            <Grid item xs={11} md={6}>
                <TextInput
                    onChange={handleChange}
                    type={'text'}
                    placeHolder={'Inserta ICC'}
                    name={'icc'}
                >
                    {data.icc}
                </TextInput>
            </Grid>

            {isReplace && (
                <>
                    <Grid item xs={12}>
                        <Typography style={{ fontSize: text }}>
                            Inserta Msisdn
                        </Typography>
                    </Grid>
                    <Grid item xs={11} md={6}>
                        <TextInput
                            onChange={handleChange}
                            type={'text'}
                            placeHolder={'Inserta Msisdn'}
                            name={'dn'}
                        >
                            {data.dn}
                        </TextInput>
                    </Grid>
                </>
            )}

            {/*
            *    <Grid item xs={11} md={6}>

            <Button onClick={() => {
                setOpen(true);
            }}>Escanear</Button>
        </Grid>
            * */}
            {
                // <Escanner setOpen={setOpen} setIcc={setIcc} open={open}/>
            }
        </Grid>
    )
}

export const Recipient = ({ data, setData }) => {
    const styles = useStyles()
    const text = useResponsiveValues(11, 13, 16)
    const [open, setOpen] = useState(false)
    const [icc, setIcc] = useState('')
    useEffect(() => {
        setData({
            ...data,
            //  icc
        })
    }, [icc])
    const handleChange = e => {
        //     setIcc(e.target.value);
        let code = getBase64(e.target.files[0])
        console.log(code)
    }

    function getBase64(file) {
        var reader = new FileReader()
        reader.readAsDataURL(file)
        let code = ''
        reader.onload = function() {
            code = reader.result
            code = code.toString().split(',')[1]
            setData({
                ...data,
                comprobante: code,
            })
        }
        reader.onerror = function(error) {
            console.log('Error: ', error)
        }
        return code
    }

    //   var file = document.querySelector('#files > input[type="file"]').files[0];
    //getBase64(file); // prints the base64 string
    return (
        <Grid container spacing={2} className={styles.productContainer}>
            <Grid item xs={12}>
                <Typography style={{ fontSize: text }}>
                    Ingresa comprobante de pago
                </Typography>
            </Grid>
            <Grid item xs={11} md={6}>
                <TextInput
                    onChange={handleChange}
                    type={'file'}
                    placeHolder={'Comprobante'}
                    name={'comprobante'}
                />
            </Grid>
        </Grid>
    )
}
