import React, {useEffect, useState} from "react";
import {Checkbox, FormControlLabel, FormGroup, Grid, Typography} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextInput from "../TextInput";
import NumberFormat from "react-number-format";

export default function Personal({dataSup, setDataSup, pre}) {
    const defaultData = {
        nombre: "",
        aparteno: "",
        amaterno: "",
        tcontacto: "",
        email: "",
	exists: false,
	gender: "",
	age: null,
    }
    const [data, setData] = useState(defaultData);
    const [exists, setExists] = useState(false);
    const {nombre, aparteno, amaterno, tcontacto, email} = data;

    useEffect(() => {
        setDataSup({
            ...dataSup,
            ...data
        });
    }, [data])
    const handleChange = e => {
        const name = e.target.name;
        const value = e.target.value;
        switch (name) {
            default:
                setData({
                    ...data,
                    [name]: value
                });
                break;
        }
    }
    return <>
<Grid container justify={'center'} spacing={1}>
        <Grid item xs={12}>
            <Typography>
                Datos personales
            </Typography>
        </Grid>
        {
            /* <Grid item xs={12}>
            <FormGroup>
                <FormControlLabel
                    control={<Checkbox checked={data.exists}
                                       onChange={({target: {checked}}) => setData({...data, exists: checked})}
                    color={"secondary"}/>}
                label={<Typography color={"primary"}>Marque esta casilla si ya es cliente
                inXel</Typography>}/>
            </FormGroup>
        </Grid>*/
        }
        {
            !data.exists && <>
                <Grid item xs={11} md={4}>
                    <TextInput
                        name={'nombre'}
                        error={false}
                        placeHolder={'Nombre'}
                        helperText={''}
                        onChange={handleChange}>
                        {nombre}
                    </TextInput>
                </Grid>
                <Grid item xs={11} md={4}>
                    <TextInput
                        name={'aparteno'}
                        error={false}
                        placeHolder={'Apellido Paterno'}
                        helperText={''}
                        onChange={handleChange}>
                        {aparteno}
                    </TextInput>
                </Grid>
                <Grid item xs={11} md={4}>
                    <TextInput
                        name={'amaterno'}
                        error={false}
                        placeHolder={'Apellido Materno'}
                        helperText={''}
                        onChange={handleChange}>
                        {amaterno}
                    </TextInput>
                </Grid>
            </>
        }
        <Grid item xs={11} md={6}>
            <NumberFormat
                name={'tcontacto'}
                placeHolder={'Telefono de contacto'}
                error={false}
                helperText={''}

                format="(###) ###-####"
                mask="_"
                onValueChange={(values) => {
                    const {formattedValue, value} = values;
                    setData({
                        ...data,
                        tcontacto: value
                    });
                }}
                customInput={TextInput}
                //        value={tcontacto}
            />
        </Grid>
        <Grid item xs={11} md={6}>
            <TextInput
                name={'email'}
                error={false}
                placeHolder={'Correo electronico'}
                helperText={''}
                onChange={handleChange}>
                {email}
            </TextInput>
        </Grid>

    </Grid>
	<div style={{ display: 'flex',  }}>
	    <div style={{ width: '50%', marginTop: 20, borderBottom: '1px solid #777', display: 'flex'  }}>
		<input 
		    name="age"
		    placeholder="Edad"
		    type="number"
		    style={{  backgroundColor: 'transparent', borderWidth: 0, fontSize: 18 }}
		    onChange={handleChange}
		/>
	    </div>

	    <div style={{ width: '50%', marginTop: 20, borderBottom: '1px solid #777', display: 'flex' }}>
		<select
		    name="gender"
		    placeholder="Género"
		    style={{ border: 'none', fontSize: 18, color: '#3c4858', backgroundColor: 'transparent' }}
		    onChange={handleChange}
		>
		    <option hidden value="">Género</option>
		    <option value="Femenino">Femenino</option>
		    <option value="Masculino">Masculino</option>
		    <option value="Otro">Otro</option>
		</select>
	    </div>
	</div>
	</>
}
