import React, {useEffect, useState} from "react";
import {
    Button,
    CircularProgress,
    Grid,
    Select,
    TextField,
    Typography,
    makeStyles,
    InputLabel,
    MenuItem,
    TableContainer,
    withStyles,
    TablePagination,
    TableFooter,
    FormControlLabel,
    FormGroup,
    Checkbox
} from "@material-ui/core";

import {Axios} from "../../../constants";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1), minWidth: 120,
    }, selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black, color: theme.palette.common.white,
    }, body: {
        fontSize: 14,
    },
}))(TableCell);

const createRecord = (id, msisdn, fecha_act, fase, fecha_ult_r, fecha_ult_m, distribuidor) => {
    return {id, msisdn, fecha_act, fase, fecha_ult_r, fecha_ult_m, distribuidor};
}
const SinRecarga = () => {
    const options = [{
        name: "Fecha", value: 1010
    }, {
        name: "Número", value: 2020
    }]
    const classes = useStyles();
    const [value, setValue] = useState(options[0].value);
    const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));
    const [rows, setRows] = useState([]);
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const [dates, setDates] = useState({
        start: "", end: ""
    });
    const [total, setTotal] = useState(0);
    const [resetRequired, setResetRequired] = useState(false);
    const [startIn, setStarin] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [lastPageLoaded, setLastPageLoaded] = useState(0);
    const [visibleRows, setVisibleRows] = useState(0);
    let axios = Axios();
    useEffect(() => {
        let defaultDate = new Date();
        let date = defaultDate.getDate();
        if (date < 10) date = `0${date}`;
        let month = defaultDate.getMonth() + 1;
        if (month < 10) month = `0${month}`;
        let defDate = `${defaultDate.getFullYear()}-${month}-${date}`;

        setDates({
            start: defDate, end: defDate
        })
    }, []);

    const handleChangePage = (event, newPage) => {
        let tv = (newPage + 1) * rowsPerPage;
        if (loading) return;
        if ((tv > rows.length) && rows.length < total) handleSearch();
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDates = (e) => {
        let fecs = dates;
        let id = e.target.id;
        switch (id) {
            case "date-start":
                fecs.start = e.target.value;
                break;
            case "date-end":
                fecs.end = e.target.value;
                break;
            default:
                return;

        }
        setResetRequired(true);
        setStarin(0);
        setDates(fecs);
    }

    const search = () => new Promise((resolve, reject) => {

        axios.get("distributor/reports/sin.r", {
            params: {
                startDate: dates.start, endDate: dates.end, start: rows.length
            }
        }).then(res => {
            setLastPageLoaded(page);
            resolve(res);
        }).catch(err => {

            reject(err);
        })
    })
    const handleReport = () => {
        let email = window.prompt("Introduce el correo al que deseas enviar el reporte");

        if (!email || email === "") {
            window.alert("Introduce un correo valido");
            return;
        }
        setLoading(true);
        axios.post("/distributor/reports/sin.r/report", {
            startDate: dates.start, endDate: dates.end, email
        }).then(res => {
            setLoading(false);
            const {data: {status, message}} = res;

            if (status !== 200) {
                window.alert(message);
                return;
            }
            window.alert("Tu reporte se esta generando, se enviara en cuanto se complete");

        }).catch(err => {
            setLoading(false);
            window.alert("Ocurrio un error al procesar tu petición verifica si tu correo o los parametros, puedes intentarlo nuevamente, si el problema persiste intenta reiniciar sesión");

        })

    }

    const handleSearch = async () => {
        setLoading(true);

        if (total === 0) {
            try {
                let {data: {status, message, total}} = await axios.get("/distributor/reports/sin.r/total", {
                    params: {
                        startDate: dates.start, endDate: dates.end, start: rows.length
                    }
                });

                console.log('Total: ', total);
                if (status !== 200) {
                    setLoading(false);
                    window.alert(message);
                    return;
                }
                setTotal(total);
            } catch (e) {

            }
        }

        search().then(res => {
            const {data: {status, data, message}} = res;
            setLoading(false);
            if (status !== 200) {
                window.alert(message);
                return;
            }

            let nRows = [];

            console.log('Total de registros: ', data.length);
            for (let i = 0; i < data.length; i++) {
                const {
                    ID, MSISDN, FECHA_ACTIVACION, FASE, FECHA_ULTIMA_RECARGA, FECHA_ULTIMA_MOVIMIENTO, DISTRIBUIDOR
                } = data[i];
                nRows.push(createRecord(ID, MSISDN, FECHA_ACTIVACION, FASE, FECHA_ULTIMA_RECARGA, FECHA_ULTIMA_MOVIMIENTO, DISTRIBUIDOR));
            }
            setRows([...rows, ...nRows]);
        }).catch(err => {
            console.error(err);
        })
    }
    const handleAttach = () => {
        setLoading(true);
        search().then(res => {
            let response = res.data;

            if (response.error) {
                window.alert(response.error_description);
                setLoading(false);
                return;
            }
            setStarin(response.next);
            setTotal(response.total);
            let befRows = rows;
            console.log(befRows)
            for (let i = 0; i < response.elements.length; i++) {
                befRows.push(response.elements[i]);
            }
            setRows(befRows);
            setLoading(false);
        }).catch(err => {
            console.error(err);
            setLoading(false);
        })
    }
    // const [options, setOptions] = useState(["Fecha", "Número"]);
    return <Grid container>
        <Grid item xs={12}>
            {/*<Grid container direction={"column"}>
                <Grid item xs={12}>
                    <Typography variant={"body2"}>
                        Filtros
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={true}
                                    // checked={state.checkedB}
                                    //   onChange={handleChange}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            disabled={true}
                            label="Fecha"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={filtros.importe}
                                    onChange={() => {
                                        setFiltros({
                                            ...filtros,
                                            importe: !filtros.importe
                                        })
                                    }}
                                    name="importeCheck"
                                    color="primary"
                                />
                            }
                            label="Importe"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={filtros.numero}
                                    onChange={() => {
                                        setFiltros({
                                            ...filtros,
                                            numero: !filtros.numero
                                        })
                                    }}
                                    name="DNCheck"
                                    color="primary"
                                />
                            }
                            label="DN"
                        />
                    </FormGroup>
                </Grid>
            </Grid>*/}
        </Grid>
        <Grid xs={12} className={"options-report"}>
            <Grid container justify={"center"} spacing={2}>

                <Grid item xs={12} md={4} lg={4} style={{
                    display: "flex", justifyContent: "space-around", alignItems: "center", paddingLeft: "1%"
                }}>
                    <TextField
                        id={'date-start'}

                        label="Desde"
                        type="date"
                        //  className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}

                        onChange={handleDates}
                    />
                    <TextField
                        id={'date-end'}
                        label="Hasta"
                        type="date"
                        //  className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}

                        onChange={handleDates}
                    />


                </Grid>

                <Grid item xs={4} md={3}
                      style={{display: "flex", justifyContent: "space-around", alignItems: "center"}}>
                    <Button variant={"contained"} onClick={handleSearch}>
                        <Typography variant={"subtitle2"}>
                            Buscar
                        </Typography>
                    </Button>
                    <Button variant={"contained"} onClick={handleReport}>
                        <Typography variant={"subtitle2"}>
                            Generar reporte
                        </Typography>
                    </Button>
                </Grid>
                <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                    <Typography variant={'overline'}>
                        El filtro se basa en la fecha de activación
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
        <Grid xs={12} className={"table-report"} style={{marginTop: "1%"}}>
            <TableContainer component={Paper}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>
                                <Typography variant={"body2"}>ID</Typography>
                            </StyledTableCell>
                            <StyledTableCell align="right">
                                <Typography
                                    variant={"body2"}>Número</Typography>
                            </StyledTableCell>
                            <StyledTableCell align="right">
                                <Typography
                                    variant={"body2"}>Fecha activacion</Typography>
                            </StyledTableCell>
                            <StyledTableCell align="right">
                                <Typography
                                    variant={"body2"}>Dias sin recarga</Typography>
                            </StyledTableCell>
                            <StyledTableCell align="right">
                                <Typography variant={"body2"}>Fecha de ultima recarga</Typography>
                            </StyledTableCell>
                            <StyledTableCell align="right">
                                <Typography variant={"body2"}>Monto de ultima recarga</Typography>
                            </StyledTableCell>
                            <StyledTableCell align="right">
                                <Typography variant={"body2"}>Distribuidor</Typography>
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.length === 0 &&
                            <Typography align={"center"} variant={"subtitle2"}>No hay nada que mostrar.</Typography>}
                        {loading ? <>
                            <CircularProgress size={50} color={"primary"}/>
                        </> : rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell component="th" scope="row">
                                    {row.id}
                                </StyledTableCell>
                                <StyledTableCell align="right">{row.msisdn}</StyledTableCell>
                                <StyledTableCell align="right">{row.fecha_act}</StyledTableCell>
                                <StyledTableCell align="right">{row.fase}</StyledTableCell>
                                <StyledTableCell align="right">{row.fecha_ult_r}</StyledTableCell>
                                <StyledTableCell align="right">{row.fecha_ult_m}</StyledTableCell>
                                <StyledTableCell align="right">{row.distribuidor}</StyledTableCell>
                            </StyledTableRow>))}
                    </TableBody>
                    <TableFooter>

                    </TableFooter>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                labelDisplayedRows={({from, to, count}) => {
                    setVisibleRows(to);
                    return `${from}-${to} de ${count}`
                }}
                labelRowsPerPage={'Filas por página'}


                component="div"
                count={total}
                rowsPerPage={rowsPerPage}

                page={page}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                onChangePage={handleChangePage}
            />
            <Grid container style={{padding: "2%"}}>
                <Grid item xs={12}>
                    <Typography variant={"body2"}>
                        * Si la fecha de ultima recarga es nula, significa que no se ha recargado nunca, esto aplica
                        tambien para el ultimo monto de recarga.
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}

export default SinRecarga;


