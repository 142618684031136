import React, {useState} from "react";
import {CircularProgress, Grid, makeStyles, Typography} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import NumberFormat from "react-number-format";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import OpenPayForm from "../OpenpayForm";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
    productContainer: {
        borderRadius: 10,
        backgroundColor: "#FEFEFE",
        padding: '1%'
    },
    text_title: {
        color: '#000000'
    },
    section: {
        marginTop: '2%'
    }
});
export default function PaymentSelect({loading, amount, onSuccess, pre}) {
    const styles = useStyles();
    const [tipoPago, setTipoPago] = useState( 'EFECTIVO');

    const handleChange = e => {
        setTipoPago(e.target.value);
    }
    return <Grid container justify={'center'}>
        <Grid item xs={10}>
            <FormControl component="fieldset" onChange={handleChange}>
                <FormLabel component="legend" className={styles.text_title}>Selecciona forma de pago</FormLabel>
                <RadioGroup row aria-label="position" name="position" defaultValue="TARJETA">
                    {
                        /* <FormControlLabel
                        className={styles.text_title}
                        value="TARJETA"
                        control={<Radio color="primary"/>}
                        label="Tarjeta crédito/débito"
                        labelPlacement="top"
                        checked={tipoPago === 'TARJETA'}
                    />
                    <FormControlLabel
                        className={styles.text_title}
                        value="TIENDA"
                        control={<Radio color="primary"/>}
                        label="Tienda"
                        labelPlacement="top"
                        checked={tipoPago === 'TIENDA'}
                    />*/
                    }
                    {
                        pre ? <FormControlLabel
                            className={styles.text_title}
                            value="EFECTIVO"
                            control={<Radio color="primary"/>}
                            label="Efectivo"
                            labelPlacement="top"
                            checked={tipoPago === 'EFECTIVO'}
                        /> : ""
                    }
                    {
                        !pre ? <FormControlLabel
                            className={styles.text_title}
                            value="TRANSFER"
                            control={<Radio color="primary"/>}
                            label="Transferencia"
                            labelPlacement="top"
                            checked={tipoPago === 'TRANSFER'}
                        /> : ""
                    }
                </RadioGroup>
            </FormControl>
        </Grid>
        {
            tipoPago === 'TARJETA' ? <Grid item xs={12}>
                <Grid container justify={'center'}>
                    <Grid item xs={11} md={11} lg={10}>
                        <OpenPayForm amount={amount} onSuccess={onSuccess}/>
                    </Grid>
                </Grid>
            </Grid> : ""
        }
        {tipoPago === 'EFECTIVO' ?
            <Grid item xs={12} style={{display: 'flex', justifyContent: "center"}}>
                {
                    loading ? <><CircularProgress/> <Typography>Guardando</Typography> </> :
                        <Button color={'primary'} variant={"contained"} onClick={onSuccess}>
                            Finalizar
                        </Button>
                }
            </Grid> : ""
        }
        {tipoPago === 'TRANSFER' ?
            <Grid item xs={12} style={{display: 'flex', justifyContent: "center"}}>
                {
                    loading ? <><CircularProgress/> <Typography>Guardando</Typography> </> :
                        <Button color={'primary'} variant={"contained"} onClick={onSuccess}>
                            Finalizar
                        </Button>
                }
            </Grid> : ""
        }
    </Grid>
}
