import React from "react";
import {Grid, makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        width: '100%',
    }
});

export default function PayForm() {
    return <Grid container>
        <h1>
            Hello
        </h1>
    </Grid>
}