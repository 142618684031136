import React, {useEffect, useState} from "react";
import {Grid, Typography} from "@material-ui/core";
import CustomerDataRequest from "../../components/CustomerDataRequest";
import VentasProductos, {DeviceData, SIMData} from "../../components/VentaProductos";
import OpenPayForm from "../../components/OpenpayForm";
import PaymentSelect from "../../components/PaymentSelect";
import ventaValidation from '../../validations/ventaValidationPre';
import AlertSnackBar from "../../components/AlertSnackBar";
import {Axios} from "../../constants";
import {useSelector} from "react-redux";

export default function NuevaVentaPre() {
    let axios = Axios();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [popup, setPopup] = useState({
        open: false,
        message: "",
        severity: "success"
    });
    const session = useSelector(state => state.detail);
    useEffect(() => {
        setData({
            ...data,
            icc: "89521400617",
            tipoVenta: "NEW",
	    // coords: {
		// lat: '',
		// lon :'',
	    // }
        })
    }, []);

    const handleSave = () => {
        setLoading(true);

	// if (data.service === "HBB") {
	//     let { lat, lon } = data.coords
	//     const regexLat = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/;
	//     const regexLon = /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/;
	//     if (!(regexLat.test(lat) && regexLon.test(lon))) {
                // setPopup({
                    // open: true,
                    // severity: "error",
                    // message: "Las coordenadas ingresadas no son válidas"
                // });
                // setLoading(false);
	// 	return
	//     }
	//     axios.get("/utils/coverage/hbb", {
	// 	params: {
	// 	    address: `${lat},${lon}`
	// 	}
	//     }).then(res => {
	// 	let { status, message } = res.data
	// 	if (status === "NOSERVICE") {
	// 	    setPopup({
	// 		open: true,
	// 		severity: "error",
	// 		message
	// 	    });
	// 	    setLoading(false);
	// 	}
	//     }).catch(e => {
                // setPopup({
                    // open: true,
                    // severity: "error",
                    // message: "Ocurrio un error inesperado validando las coordenadas"
                // });
                // setLoading(false);
	//     })
	// }

        axios.get(`/distributor/imei.validation/${data.imei}`).then(res => {
            if (res.data.status !== 'OK') {
                setPopup({
                    open: true,
                    severity: "error",
                    message: res.data.message
                });
                setLoading(false);
            } else {
                if (res.data.message === "VOZAPP") {
                    if (data.product !== "11181") {
                        setPopup({
                            open: true,
                            severity: "error",
                            message: "Activaciones con VozApp, solo con recarga de $50.00"
                        });
                        setLoading(false);
                        return;
                    }
                }
                const verify = ventaValidation.validate(data);
                if (verify.error) {
                    setPopup({
                        open: true,
                        severity: "error",
                        message: verify.error.message
                    });
                    setLoading(false);
                    return;
                }
                let datas = data;
                datas.notification_id = session.notification_id;
                datas.marca = res.data.device.brand;
                datas.modelo = res.data.device.model;
                axios.get(`/distributor/verify/icc/${data.icc.toUpperCase()}`).then(res => {
                    axios.post('distributor/save/buy/prepago', datas).then(res => {
                        let response = res.data;
                        setLoading(false);
                        if (response.error) {
                            setPopup({
                                open: true,
                                severity: "error",
                                message: response.errorDescription
                            });
                            return;
                        }
                        setPopup({
                            open: true,
                            severity: "success",
                            message: 'Venta enviada'
                        });
                        window.location.reload();
                    }).catch(error => {
                        setPopup({
                            open: true,
                            severity: "error",
                            message: 'Hubo un error al enviar venta.'
                        })
                        setLoading(false);
                    })
                }).catch(err => {
                    setPopup({
                        open: true,
                        severity: "error",
                        message: err.status === 404 ? "ICC no existe" : "Error al validar ICC verifica que este bien escrito"
                    })
                    setLoading(false);
                });
            }
        }).catch(err => {
            setPopup({
                open: true,
                severity: "error",
                message: 'Error al validar el imei'
            });
            setLoading(false);
        });
    }
    const onClose = () => {
        setPopup({
            ...popup,
            open: false
        });
    }

    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <Typography variant={'h5'}>
                Datos de cliente
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <CustomerDataRequest data={data} setData={setData} pre={true}/>
        </Grid>
        <Grid item xs={12}>
            <Typography variant={'h5'}>
                Producto
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <VentasProductos data={data} setData={setData} pre={true}/>
        </Grid>
        <Grid item xs={12} style={{marginTop: '1%'}}>
            <Typography variant={'h5'}>
                Dispositivo
            </Typography>
        </Grid>
        <Grid item xs={12} style={{marginBottom: '1%'}}>
            <DeviceData data={data} setData={setData}/>
        </Grid>
        <Grid item xs={12} style={{marginTop: '1%'}}>
            <Typography variant={'h5'}>
                Datos de SIM
            </Typography>
        </Grid>
        <Grid item xs={12} style={{marginBottom: '1%'}}>
            <SIMData data={data} setData={setData}/>
        </Grid>
        <Grid item xs={12}>
            <Typography variant={'h5'}>
                Datos de pago
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <PaymentSelect loading={loading} amount={data.amount} onSuccess={handleSave} pre={true}/>
        </Grid>
        <AlertSnackBar open={popup.open} severity={popup.severity} onClose={onClose} autoHideDuration={4000}>
            {
                popup.message
            }
        </AlertSnackBar>
    </Grid>
}
