import React, {useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from "@material-ui/core/Snackbar";
import {Axios, XEL_FONT} from "../../constants";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '60vh'
    },
    msg_nop: {
        fontSize: 13,
        color: '#000000',
        fontWeight: XEL_FONT.semi_bold_weight
    }
    ,
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function LoginForm({register}) {
    const classes = useStyles();
    const mailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const dispatch = useDispatch();
    const session = useSelector(state => state.login);
    const [login, setLogin] = useState(true);
    const [onLoad, setOnLoad] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const defaultData = {
        name: '',
        apps: '',
        email: '',
        phone: '',
        password: '',
        repassword: '',
        code: ''
    };
    const [data, setData] = useState(defaultData);
    const [open, setOpen] = useState({
        open: false,
        msg: '',
        type: 'error'
    });
    const defaultDataErrors = {
        name: false,
        name_m: '',
        apps: false,
        apps_m: '',
        email: false,
        email_m: '',
        phone: false,
        phone_m: '',
        password: false,
        password_m: '',
        repassword: false,
        repassword_m: '',
        code: false,
        code_m: ''
    };
    const [dataErrors, setDataErrors] = useState(defaultDataErrors);
    const [option, setOption] = useState(register ? 'REGISTER' : 'LOGIN');
    const history = useHistory();
    const {email,password} = data;
    const axios = Axios();

    useEffect(() => {
        if (session.logged) {
            history.push('/');
        } else {
            setLoaded(true);
        }
    }, [])

    const handleChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
        setDataErrors({
            ...dataErrors,
            [e.target.name]: false
        })
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        let errors = dataErrors;
        if (email.trim().split('').length === 0) {
            errors.email = true;
            errors.email_m = 'Introduce correo';
        } else if (!mailReg.test(email.trim().toLowerCase())) {
            errors.email = true;
            errors.email_m = 'Correo inválido';
        }
            if (password.split('').length === 0) {
                errors.password = true;
                errors.password_m = 'Introduce contraseña';
            }

        if (errors.password || errors.email || errors.code || errors.repassword) {
            setDataErrors({...errors});
            return;
        }
        setOnLoad(true);
            let form = new FormData();
            form.append('email', data.email.trim().toLowerCase());
            form.append('password', data.password);
                axios.post('/distributor/login', {
                    email: data.email.trim().toLowerCase(),
                    password: data.password
                }).then(({ data: resData }) => {
		    Array.from(Object.keys(resData)).forEach(resDataKey => 
			window.localStorage.setItem(resDataKey, resData[resDataKey])
		    )
                    window.location.reload();
                }).catch(err => {
		    console.log( 'login error:', err )
                    setOnLoad(false);
                    setOpen({
                        open: true,
                        type: 'error',
                        msg: 'Correo o contraseña incorrectas'
                    })
                });
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen({
            ...open,
            open: false
        });
    };
    return (
        <>
            {
                loaded ? <Container component="main" maxWidth="xs">
                    {
                        <Snackbar open={open.open} autoHideDuration={3500} onClose={handleClose}>
                            <Alert onClose={handleClose} severity={open.type}>
                                {open.msg}
                            </Alert>
                        </Snackbar>
                    }
                    <CssBaseline/>
                    <div className={classes.paper}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                                <Avatar className={classes.avatar}>
                                    <LockOutlinedIcon/>
                                </Avatar>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography component="h1" variant="h5" align={'center'}>
                                   Iniciar sesión
                                </Typography>
                            </Grid>

                        </Grid>
                        <form className={classes.form} noValidate onSubmit={handleSubmit}>
                        <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Correo electrónico"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    value={email}
                                    onChange={handleChange}
                                    error={dataErrors.email}
                                    helperText={dataErrors.email ? dataErrors.email_m : ''}
                                    disabled={onLoad}
                                />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Contraseña"
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"
                                        value={password}
                                        onChange={handleChange}
                                        error={dataErrors.password}
                                        helperText={dataErrors.password ? dataErrors.password_m : ''}
                                        disabled={onLoad}
                                    />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                disabled={onLoad}
                            >
                                        Iniciar
                            </Button>
                        </form>
                    </div>
                </Container> : ''
            }
        </>
    );
}
