import React, {useEffect, useState} from "react";
import {Button, Checkbox, FormControl, FormHelperText, Grid, Input, InputLabel, Typography} from "@material-ui/core";
import {Axios} from "../../constants";
import {XEL_COLORS, XEL_FONT} from "../../constants";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {makeStyles} from "@material-ui/core/styles";
import getPlanes from "../../services/getPlanes";
import VentasProductos, {Extras, Products} from "../../components/VentaProductos";
import Snackbar from "@material-ui/core/Snackbar";
import {Alert} from "@material-ui/lab";
import {useSelector} from "react-redux";

const useStyles = makeStyles({
    header: {
        backgroundColor: XEL_COLORS.secondary,
        borderRadius: 5,
        padding: "1%"
    },
    cardContainer: {
        borderRadius: 5,
        backgroundColor: "#FEFEFE",
        boxShadow: "-1px 2px 6px 0px rgba(0,0,0,0.75)"
    },
    inputcontainer: {
        marginBottom: "1%"
    },
    label: {
        fontSize: 13
    }
});


const Migraciones = () => {
    const styles = useStyles();
    const axios = Axios();
    const [enableExtras, setEnableExtras] = useState(false);
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [params, setParams] = useState({});
    const [continueAwait, setContinueAwait] = useState(false);
    const [selectedIdP, setSelectedIdP] = useState("");
    const session = useSelector(state => state.detail);

    const [alert, setAlert] = useState({
        open: false,
        message: "",
        severity: "success"
    });

    useEffect(() => {
        console.log("Per", session)
    }, [session])

    useEffect(() => {
        if (selectedIdP) {
            setParams({
                ...params,
                productId: parseInt(selectedIdP)
            })
            if (parseInt(selectedIdP) === 39846) {
                if (session.permisos.extras)
                    setEnableExtras(true)
            } else {
                setEnableExtras(false);
                setServices([]);
            }
        }
    }, [selectedIdP]);

    const addExtraService = (Id) => {
        let ser = services;
        if (!ser)
            ser = [];
        ser.push(parseInt(Id));
        setServices(ser);
    }
    const delExtraService = (Id) => {
        let ser = services;
        if (!ser)
            ser = [];
        let nServices = [];
        for (let i = 0; i < ser.length; i++) {
            if (Id !== services[i])
                nServices.push(services[i]);
        }
        setServices(nServices);
    }

    const onChange = (e) => {
        let target = e.target;
        let {name, value} = target;
        switch (name) {
            case "msisdn":
                if (isNaN(value))
                    return;
                if (value.length > 10)
                    return;
                if (value.length > 0)
                    value = parseInt(value);
                break;
        }

        setParams({
            ...params,
            [name]: value
        })
    }

    const onSend = () => {

        let {msisdn, productId, code, idOp} = params;
        setLoading(true);
        axios.patch(`/distributor/migrations/migrate/${msisdn}`, {
            productId,
            extras: services,
            code,
            idOp
        }).then(res => {
            setLoading(false);
            let {data} = res;
            if (data.error) {
                setAlert({
                    open: true,
                    severity: "error",
                    message: data.error_description
                });
                return;
            }
            if (continueAwait) {
                setParams({});
                setServices([]);
                setContinueAwait(false);
                setAlert({
                    open: true,
                    severity: "success",
                    message: "Migración completada con exito"
                })
            } else {
                setAlert({
                    open: true,
                    severity: "success",
                    message: "Codigo de autorización enviado al cliente."
                });
                setParams({
                    ...params,
                    idOp: data.idOp
                })
                setContinueAwait(true);
            }
        }).catch(err => {
            setLoading(false);
            setAlert({
                open: true,
                severity: "error",
                message: "Problema de conexión."
            });
        });
    }

    return <Grid container>
        <Grid item xs={12}>
            <Typography variant={"body1"}>
                Migraciones inXel a postpago
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Grid container spacing={3} justify={"center"}>
                <Grid item xs={12}>
                    <Products data={params}
                              setData={() => {
                              }}
                              service={"MOVIL"} selectProduct={setSelectedIdP}/>
                </Grid>
                {
                    enableExtras && <Extras addExtraService={addExtraService} delExtraService={delExtraService}/>
                }
                <Grid item xs={11} sm={6} md={4}>
                    <FormControl disabled={loading || continueAwait} fullWidth
                                 className={styles.inputcontainer}>
                        <InputLabel htmlFor="long">Número inXel</InputLabel>
                        <Input onChange={onChange} value={params.msisdn} name={"msisdn"} id="dn-ipt"
                               aria-describedby="msisdn"/>
                    </FormControl>
                    {
                        continueAwait && <FormControl disabled={loading} fullWidth
                                                      className={styles.inputcontainer}>
                            <InputLabel htmlFor="long">Código de autorización</InputLabel>
                            <Input onChange={onChange} value={params.code} name={"code"} id="code-ipt"
                                   aria-describedby="code"/>
                            <FormHelperText
                                id="code-helper">
                                Introduce el código que recibio el cliente y presiona Continuar
                            </FormHelperText>
                        </FormControl>
                    }
                    <Button disabled={loading} onClick={onSend} variant={"contained"} color={"primary"}
                            style={{marginTop: "2%"}}>
                        {
                            continueAwait ? "Continuar" : "Enviar"
                        }
                    </Button>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12}>
            <Snackbar open={alert.open} autoHideDuration={10000}
            >
                <Alert style={{fontSize: 23, fontWeight: "700"}} onClose={() => setAlert({...alert, open: false})}
                       severity={alert.severity}>
                    {alert.message}
                </Alert>
            </Snackbar>
        </Grid>
    </Grid>

}

export default Migraciones;
