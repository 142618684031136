import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AlertSnackBar({open, autoHideDuration, onClose, severity, children}) {

    return <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={onClose}>
        <Alert onClose={onClose}  severity={severity}>
            {children}
        </Alert>
    </Snackbar>
}
