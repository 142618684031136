import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {Button, Checkbox, FormControlLabel, TextField, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {XEL_COLORS} from "../../constants";
import {useSelector} from "react-redux";
import {Axios} from "../../constants";
/*
* Nombre Completo
* Email
* Permisos
* */

const useStyles = makeStyles({
    label: {
        fontSize: 13
    }
});
const AddColaborator = ({update, setUpdating}) => {
    const session = useSelector(state => state.login);
    const detail = useSelector(state => state.detail);
    const axios = Axios();

    const defaultPerms = {
        prepago: {
            name: "Venta prepago",
            active: false
        },
        postpago: {
            name: "Venta postpago",
            active: false

        },
        mifiprepago: {
            name: "Venta Mi-FI prepago",
            active: false
        },
        mifipostpago: {
            name: "Venta Mi-Fi postpago",
            active: false

        },
        recargas: {
            name: "Recargas",
            active: false
        },
    };
    const [perms, setPerms] = useState(defaultPerms);
    const styles = useStyles();
    const [params, setParams] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let permisos = {
            postpago: perms.postpago.active,
            prepago: perms.prepago.active,
            mifiprepago: perms.mifipostpago.active,
            mifipostpago: perms.mifiprepago.active,
            recargas: perms.recargas.active,
        };
        let vParams = params;
        vParams = {
            ...vParams,
            permisos
        }
        setParams(vParams);
    }, [perms]);


    const onChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setParams({
            ...params,
            [name]: value
        });
    }

    const onSave = () => {
        setLoading(true);
        axios.post("/distributor/add.seller", params).then(res => {
            let response = res.data;

            setLoading(false);
            if (response.error) {
                window.alert(response.error_description);
            } else
                window.alert("Se ha enviado un correo de activacion al colaborador");

            let permisos = {
                postpago: perms.postpago.active,
                prepago: perms.prepago.active,
                mifiprepago: perms.mifipostpago.active,
                mifipostpago: perms.mifiprepago.active,
                recargas: perms.recargas.active,
            };
            setParams({
                ...permisos
            });

        }).catch(err => {
            console.error(err);
            window.alert("Error al realizar la conexión, intenta más tarde.");
            setLoading(false);
        });
    }
    const onUpdate = () => {
        setLoading(true);
        axios.post(`/distributor/update.seller/${update.id}`, {
            permisos: params.permisos
        }).then(res => {
            let response = res.data;
            setLoading(false);
            if (response.error) {
                window.alert(response.error_description);
            } else
                window.alert("Permisos actualizados");
            window.location.reload();
        }).catch(err => {
            console.error(err);
            window.alert("Error al realizar la conexión, intenta más tarde.");
            setLoading(false);
        });
    }
    return <Grid container spacing={2} alignItems={"center"} justify={"center"}>
        <Grid item xs={11} md={5} lg={3}>
            <TextField onChange={onChange} disabled={update.update} value={update.update ? "" : params.name}
                       size={"small"} name="username"
                       id="username" fullWidth
                       label={update.update ? update.user : "Usuario"}
                       variant="filled"/>
        </Grid>
        <Grid item xs={11} md={5} lg={3}>
            <TextField onChange={onChange} disabled={update.update} value={update.update ? "" : params.fullname}
                       size={"small"}
                       name="fullname" id="fullname"
                       fullWidth
                       label={update.update ? update.name : "Nombre completo"} variant="filled"/>
        </Grid>
        <Grid item xs={11} md={5} lg={3}>
            <TextField onChange={onChange} disabled={update.update} value={update.update ? "" : params.email}
                       size={"small"} name="email"
                       id="email" fullWidth
                       label={update.update ? update.email : "Email"}
                       variant="filled" type={"email"}/>
        </Grid>
        <Grid item xs={11} md={5} lg={3}>
            <Typography variant={'body2'}>
                Permisos:
            </Typography>
            {
                detail.permisos.prepago && <FormControlLabel
                    control={<Checkbox checked={perms.prepago.active} onChange={() => {
                        setPerms({
                            ...perms,
                            prepago: {
                                name: perms.prepago.name,
                                active: !perms.prepago.active
                            }
                        })
                    }} name="venta_pre"/>}
                    classes={{
                        label: styles.label
                    }}
                    label={perms.prepago.name}
                />
            }
            {
                detail.permisos.postpago && <FormControlLabel
                    classes={{
                        label: styles.label
                    }}
                    control={<Checkbox checked={perms.postpago.active} onChange={() => {
                        setPerms({
                            ...perms,
                            postpago: {
                                name: perms.postpago.name,
                                active: !perms.postpago.active
                            }
                        })
                    }} name="venta_post"/>}
                    label={perms.postpago.name}
                />
            }
            {
                detail.permisos.mifiprepago && <FormControlLabel
                    control={<Checkbox checked={perms.mifiprepago.active} onChange={() => {
                        setPerms({
                            ...perms,
                            mifiprepago: {
                                name: perms.mifiprepago.name,
                                active: !perms.mifiprepago.active
                            }
                        })
                    }} name="venta_pre"/>}
                    classes={{
                        label: styles.label
                    }}
                    label={perms.mifiprepago.name}
                />
            }
            {
                detail.permisos.mifipostpago && <FormControlLabel
                    classes={{
                        label: styles.label
                    }}
                    control={<Checkbox checked={perms.mifipostpago.active} onChange={() => {
                        setPerms({
                            ...perms,
                            mifipostpago: {
                                name: perms.mifipostpago.name,
                                active: !perms.mifipostpago.active
                            }
                        })
                    }} name="venta_post"/>}
                    label={perms.mifipostpago.name}
                />
            }
            {
                detail.permisos.recargas && <FormControlLabel
                    classes={{
                        label: styles.label
                    }}
                    control={<Checkbox checked={perms.recargas.active} onChange={() => {
                        setPerms({
                            ...perms,
                            recargas: {
                                name: perms.recargas.name,
                                active: !perms.recargas.active
                            }
                        })
                    }} name="venta_post"/>}
                    label={perms.recargas.name}
                />
            }

        </Grid>
        <Grid item xs={11} md={4} lg={2} style={{display: "flex", justifyContent: "center"}}>
            <Button variant={"contained"} style={{padding: "1%"}} color={"primary"}
                    onClick={!update.update ? onSave : onUpdate}>
                {
                    !update.update ? "Añadir" : "Actualizar"
                }
            </Button>
            {
                update.update &&
                <Button style={{marginLeft: "3%", color: "#FEFEFE", padding: "1%"}} variant={"contained"}
                        color={"secondary"} onClick={() => setUpdating({
                    ...update,
                    update: false
                })}>
                    Cancelar
                </Button>
            }
        </Grid>
    </Grid>
}

const Colaborator = ({id, fullname, email, username, permisos, onUpdate, xelcash}) => {
    let axios = Axios();
    const [loading, setLoading] = useState(false);
    const [perms, setPerms] = useState({});
    const details = useSelector(state => state.detail);
    useEffect(() => {
	    console.log( 'permisos:', permisos )
	    if (typeof permisos === 'string')
		setPerms(JSON.parse(permisos))
    }, [])
    const deactivateUser = () => {
        setLoading(true);
        axios.patch(`/distributor/deactivate.seller/${id}`).then(res => {
            let response = res.data;
            setLoading(false);
            if (response.error) {
                window.alert(response.error_description);
                return;
            }
            window.alert("Usuario eliminado");
            window.location.reload();
        }).catch(err => {
            setLoading(false);
            console.error(err);
            window.alert("Hubo un error al conectarse, intenta más tarde.");
        })
    }
    const updatePassword = () => {
        setLoading(true);
        axios.patch(`/distributor/reset.password/${id}`).then(res => {
            let response = res.data;
            setLoading(false);
            if (response.error) {
                window.alert(response.error_description);
                return;
            }
            window.alert("Correo para cambiar la contraseña enviada");
            window.location.reload();
        }).catch(err => {
            setLoading(false);
            console.error(err);
            window.alert("Hubo un error al conectarse, intenta más tarde.");
        })
    }
    const processXELCASH = (action) => {
        let add = action === "add";
        if (add)
            if (details.xelcash < 100) {
                window.alert("No tienes suficiente XELCASH para realizar esta acción");
                return;
            }
        let prompt;
        if (add)
            prompt = window.prompt("Ingresa la cantidad de XELCASH que deseas darle al usuario, disponible: $" + parseFloat(details.xelcash).toFixed(2));
        else
            prompt = window.prompt("Ingresa la cantidad de XELCASH que deseas remover del usuario, disponible: $" + parseFloat(xelcash).toFixed(2));

        if (prompt && !isNaN(parseInt(prompt))) {
            let value = parseInt(prompt);

            if (add)
                if (value <= 99) {
                    window.alert("El valor debe ser mayor a 99");
                    return;
                }

            axios.post(`/distributor/seller.xelcash/${action}`, {
                id: id,
                amount: value
            }).then(res => {
                let response = res.data;
                if (response.error) {
                    window.alert(response.error_description);
                    return;
                }
                if (add)
                    window.alert("XELCASH otorgado");
                else
                    window.alert("XELCASH removido");
                window.location.reload();
            }).catch(err => {
                console.error(err);
                window.alert("Hubo un error al conectarse, intenta más tarde.");
            })
        } else {
            window.alert("Ingresa una cantidad válida");
        }
    }
    return <Grid container spacing={2} alignItems={"center"} justify={"center"}
                 style={{
                     borderRadius: 10,
                     border: `1px solid ${XEL_COLORS.primary_light}`,
                     backgroundColor: "rgba(90,53,113,0.03)",
                     marginTop: 10
                 }}>
        <Grid item xs={11} md={5} lg={3}>
            <Typography variant={"body2"}>
                {fullname}
            </Typography>
        </Grid>
        <Grid item xs={11} md={5} lg={3}>

            <Typography variant={"body2"}>
                Usuario: {username}
            </Typography>
            <Typography variant={"body2"}>
                Email: {email}
            </Typography>
            <Typography>
                XELCASH: ${xelcash}
            </Typography>
        </Grid>
        <Grid item xs={11} md={5} lg={3}>
            <Typography variant={'body2'}>
                Permisos del colaborador:
            </Typography>

            <Typography variant={"body2"} style={{color: XEL_COLORS.primary}}>
                Venta prepago:{perms.prepago ? "SI" : "NO"}
            </Typography>
            <Typography variant={"body2"} style={{color: XEL_COLORS.primary}}>
                Venta postpago:{perms.postpago ? "SI" : "NO"}
            </Typography>
            <Typography variant={"body2"} style={{color: XEL_COLORS.primary}}>
                Venta Mi-Fi postpago:{perms.mifipostpago ? "SI" : "NO"}
            </Typography>
            <Typography variant={"body2"} style={{color: XEL_COLORS.primary}}>
                Venta Mi-Fi prepago:{perms.mifiprepago ? "SI" : "NO"}
            </Typography>
            <Typography variant={"body2"} style={{color: XEL_COLORS.primary}}>
                Recargas:{perms.recargas ? "SI" : "NO"}
            </Typography>
        </Grid>
        <Grid item xs={11} md={4} lg={2} style={{display: "flex", justifyContent: "center", flexDirection: "column"}}>

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Button style={{backgroundColor: "green"}} disabled={loading} onClick={() => {
                        processXELCASH("add");
                    }}
                            variant={"contained"}>
                        <Typography variant={"body2"} style={{color: "#FEFEFE"}}>
                            Transferir XELCASH
                        </Typography>
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Button style={{backgroundColor: "green"}} disabled={loading} onClick={() => {
                        processXELCASH("remove");
                    }}
                            variant={"contained"}>
                        <Typography variant={"body2"} style={{color: "#FEFEFE"}}>
                            Remover XELCASH
                        </Typography>
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Button style={{backgroundColor: "red"}} disabled={loading} onClick={deactivateUser}
                            variant={"contained"}>
                        <Typography variant={"body2"} style={{color: "#FEFEFE"}}>
                            Eliminar
                        </Typography>
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Button variant={"contained"} disabled={loading} onClick={() => {
                        window.scrollTo(0, 0);
                        onUpdate({
                            update: true,
                            user: username,
                            email,
                            name: fullname,
                            id
                        })
                    }
                    } color={"primary"}>
                        <Typography variant={"body2"}>
                            Actualizar
                        </Typography>
                    </Button>
                </Grid>
                <Grid item xs={12}>

                    <Button variant={"contained"} onClick={updatePassword} disabled={loading} color={"secondary"}>
                        <Typography variant={"body2"} style={{color: "#FEFEFE"}}>
                            Cambiar contraseña
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}

const Colaboradores = () => {
    const [colaborators, setColaboratos] = useState([]);
    const axios = Axios();
    const [updating, setUpdating] = useState({
        update: false,
        user: "",
        name: "",
        email: "",
    });
    useEffect(() => {
        axios.get("/distributor/colaborators").then(res => {

            let response = res.data;

            if (response.error) {
                window.alert(response.error_description);
                return;
            }
            setColaboratos(response);
        }).catch(err => {
            console.error(err);
            window.alert("Error al consultar tu información");
        })
    }, [])
    return <Grid container spacing={2} style={{padding: 1}}>
        <Grid item xs={12}>
            <Typography>
                Agrega o deshabilita tus cuentas de colaboradores.
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Typography variant={"body2"} align={"center"}>
                Agregar
            </Typography>
        </Grid>
        <Grid item xs={12} style={{borderBottom: `1px solid ${XEL_COLORS.lila}`}}>
            <AddColaborator update={updating} setUpdating={setUpdating}/>
        </Grid>
        <Grid item xs={12}>
            <Grid container style={{height: "100%", width: "100%", overflow: "scroll"}} justify={"center"} spacing={1}>
                {
                    colaborators.map((colaborator, index) => <Grid key={index} item xs={11}>
                        <Colaborator id={colaborator.id} fullname={colaborator.name} email={colaborator.email}
                                     username={colaborator.user} xelcash={colaborator.xelcash}
                                     permisos={colaborator.permisos}
                                     onUpdate={setUpdating}/>
                    </Grid>)
                }
            </Grid>
        </Grid>
    </Grid>
}


export default Colaboradores;
